import { Component, OnInit } from '@angular/core';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'app/main/service/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentsService } from 'app/main/components/components.service';
import { FilesApiService } from 'app/main/service/files-api.service';

@Component({
  selector: 'app-event-bonus-manage',
  templateUrl: './event-bonus-manage.component.html',
  styleUrls: ['./event-bonus-manage.component.scss'],
})
export class EventBonusManageComponent implements OnInit {
  contentHeader: ContentHeader;

  isCodeError = false;
  isFileError = false;
  isCanSubmitCode = false;
  isCanSubmitFile = false;

  valueCode = '';
  valueFile = '';
  fileName = '';

  periodCodeS!: any;
  periodCodeE!: any;
  periodIdCode!: string;
  periodFileS!: any;
  periodFileE!: any;
  periodIdFile!: string;
  periodList!: any[];

  @BlockUI() blockUI: NgBlockUI;

  private _unsubscribeAll = new Subject();

  constructor(
    private _apiService: ApiService,
    private _componentsService: ComponentsService,
    private _fileApiService: FilesApiService
  ) {
    this.GetPeriodList();
  }

  GetPeriodList(): void {
    this._apiService
      .GetAllData('mlm/SalePeriod', { Direction: 1 })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.periodList = res.data.resultData;
        this.periodIdCode = res.data.resultData[0].id;
        this.periodIdFile = res.data.resultData[0].id;

        const startDate = res.data.resultData[0].startDate;
        const endDate = res.data.resultData[0].endDate;

        this.periodCodeS = startDate;
        this.periodCodeE = endDate;
        this.periodFileS = startDate;
        this.periodFileE = endDate;
      });
  }

  selectedPeriod(id: any, start: any, end: any) {
    this.periodIdCode = id;
    this.periodCodeS = start;
    this.periodCodeE = end;
  }

  selectedPeriod2(id: any, start: any, end: any) {
    this.periodIdFile = id;
    this.periodFileS = start;
    this.periodFileE = end;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BonusPlan.EventBonusManage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  onValueChang(event) {
    const value = event.target.value;

    console.log(value.length);

    if (value.length == 10) {
      this.isCanSubmitCode = true;
      this.isCodeError = false;
      this.valueCode = value;
    } else {
      this.isCanSubmitCode = false;
      this.isCodeError = true;
      this.valueCode = '';
    }
  }

  onFileChange(event) {
    const file = event.target.files[0];

    console.log(file);

    if (
      file.type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      console.log('true');
      this.fileName = file.name;
      this.isCanSubmitFile = true;
      this.isFileError = false;
      this.valueFile = file;
    } else {
      console.log('false');
      this.fileName = '';
      this.isCanSubmitFile = false;
      this.isFileError = true;
      this.valueFile = '';
    }
  }

  submitCode() {
    this.blockUI.start();
    console.log(this.valueCode, ' : ', this.periodIdCode);

    const body = {
      repoveCode: this.valueCode,
      periodId: this.periodIdCode,
    };

    this.saveEventBonusCode(body)
      .then(() => {
        this.blockUI.stop();
        this._componentsService.SuccessSwal();
      })
      .catch(() => {
        this.blockUI.stop();
        this._componentsService.ErrorSwal();
      });
  }

  submitFile() {
    this.blockUI.start();
    console.log(this.valueFile, ' : ', this.periodIdFile);

    this.saveEventBonusExcel(this.periodIdFile, this.valueFile)
      .then(() => {
        this.blockUI.stop();
        this._componentsService.SuccessSwal();
      })
      .catch(() => {
        this.blockUI.stop();
        this._componentsService.ErrorSwal();
      });
  }

  saveEventBonusCode(data: {
    repoveCode: string;
    periodId: string;
  }): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService.AddData('importAPC/importAPC', data).subscribe(
        (res) => {
          resolve();
        },
        (err) => {
          rejects();
        }
      );
    });
  }

  saveEventBonusExcel(periodId: string, file: any): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);

    console.log(file);

    return new Promise((resolve, rejects) => {
      this._fileApiService
        .AddExcel(
          `importAPCsExcel/importAPCsExcel?periodId=${periodId}`,
          formData
        )
        .subscribe(
          (res) => {
            resolve();
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  ExportXLSX() {
    this._fileApiService.getExcelReport(
      'Excel/GetAPCsExcelForm',
      'Event Bonus Template'
    );
  }
}
