<div class="content-wrapper container-xxl p-0">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>

  <div class="content-body">
    <div class="card">
      <div class="card-body">
        <table class="table table-bordered system-bonus-table">
          <thead>
            <tr>
              <th width="60%">{{ "BonusPlan.Condition" | translate }}</th>
              <th width="12%" class="text-center active-pv">AP</th>
              <th width="12%" class="text-center master-pv">MP</th>
              <th width="12%" class="text-center">
                {{ "General.Manage" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let bonus of systemBonusDataList; let i = index"
              [class.selected-row]="i === selectedRowIndex"
            >
              <td>
                <div
                  *ngIf="bonus.conditionName === 'PresonalSV30K'"
                  class="d-flex align-items-center"
                >
                  <span
                    *ngIf="selectedRowIndex !== i; else editSV"
                    class="mr-1"
                  >
                    AP SV {{ bonus.apsvRate || 0 }}+, MP SV
                    {{ bonus.mpsvRate || 0 }}+
                  </span>
                  <ng-template #editSV>
                    <label for=""> AP </label>
                    <input
                      class="form-control text-end w-25 mx-1"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      (change)="onDataChange()"
                      [(ngModel)]="bonus.apsvRate"
                      [ngClass]="{ 'is-invalid': isSave && bonus.apsvRate < 0 }"
                    />

                    <label for=""> MP </label>
                    <input
                      class="form-control text-end w-25 mx-1"
                      type="number"
                      (change)="onDataChange()"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      [(ngModel)]="bonus.mpsvRate"
                      [ngClass]="{ 'is-invalid': isSave && bonus.mpsvRate < 0 }"
                    />
                  </ng-template>
                  {{
                    "MLM.SystemBonusCondition." + bonus.conditionName
                      | translate
                  }}
                </div>
                <span *ngIf="bonus.conditionName !== 'PresonalSV30K'">
                  {{
                    "MLM.SystemBonusCondition." + bonus.conditionName
                      | translate
                  }}
                </span>
              </td>
              <td class="text-center active-pv">
                <div *ngIf="selectedRowIndex !== i; else editAPMode">
                  <p
                    class="m-0"
                    *ngIf="
                      bonus.psApSelect ||
                      bonus.apSelectedBonus == 1 ||
                      bonus.apSelectedBonus == 3
                    "
                  >
                    PS {{ bonus.apPercentage }}%
                  </p>
                  <p
                    class="m-0"
                    *ngIf="
                      bonus.dsApSelect ||
                      bonus.apSelectedBonus == 2 ||
                      bonus.apSelectedBonus == 3
                    "
                  >
                    DS {{ bonus.dsapPercentage }}%
                  </p>
                </div>
                <ng-template #editAPMode>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label for="ps1" class="m-0 d-flex align-items-center">
                      <input
                        id="ps1"
                        type="checkbox"
                        name="ps1"
                        class="inputCheck"
                        [(ngModel)]="bonus.psApSelect"
                      />
                      PS
                    </label>
                    <input
                      class="form-control text-end inputPoint"
                      type="number"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                      (change)="onDataChange()"
                      [(ngModel)]="bonus.apPercentage"
                      [ngClass]="{ 'is-invalid': isSave && bonus.apPercentage == null }"
                    />
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center space"
                  >
                    <label for="ds1" class="m-0 d-flex align-items-center">
                      <input
                        id="ds1"
                        type="checkbox"
                        name="ds1"
                        class="inputCheck"
                        [(ngModel)]="bonus.dsApSelect"
                      />
                      DS
                    </label>
                    <input
                      class="form-control text-end inputPoint"
                      type="number"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                      (change)="onDataChange()"
                      [(ngModel)]="bonus.dsapPercentage"
                      [ngClass]="{ 'is-invalid': isSave && bonus.dsapPercentage == null}"
                    />
                  </div>
                </ng-template>
              </td>
              <td class="text-center master-pv">
                <span *ngIf="selectedRowIndex !== i; else editMPMode">
                  <p
                    class="m-0"
                    *ngIf="
                      bonus.psMpSelect ||
                      bonus.mpSelectedBonus == 1 ||
                      bonus.mpSelectedBonus == 3
                    "
                  >
                    PS {{ bonus.mpPercentage }}%
                  </p>
                  <p
                    class="m-0"
                    *ngIf="
                      bonus.dsMpSelect ||
                      bonus.mpSelectedBonus == 2 ||
                      bonus.mpSelectedBonus == 3
                    "
                  >
                    DS {{ bonus.dsmpPercentage }}%
                  </p>
                </span>
                <ng-template #editMPMode>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label for="ps2" class="m-0 d-flex align-items-center">
                      <input
                        id="ps2"
                        type="checkbox"
                        name="ps2"
                        class="inputCheck"
                        [(ngModel)]="bonus.psMpSelect"
                      />
                      PS
                    </label>
                    <input
                      class="form-control text-end inputPoint"
                      type="number"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                      (change)="onDataChange()"
                      [(ngModel)]="bonus.mpPercentage"
                      [ngClass]="{ 'is-invalid': isSave && bonus.mpPercentage == null }"
                    />
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center space"
                  >
                    <label for="ds2" class="m-0 d-flex align-items-center">
                      <input
                        id="ds2"
                        type="checkbox"
                        name="ds2"
                        class="inputCheck"
                        [(ngModel)]="bonus.dsMpSelect"
                      />
                      DS
                    </label>
                    <input
                      class="form-control text-end inputPoint"
                      type="number"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                      (change)="onDataChange()"
                      [(ngModel)]="bonus.dsmpPercentage"
                      [ngClass]="{ 'is-invalid': isSave && bonus.dsmpPercentage == null }"
                    />
                  </div>
                </ng-template>
              </td>
              <td
                class="action-column-butt d-flex justify-content-center border-bottom-0 border-left-0 border-right-0"
              >
                <a
                  class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                  *ngIf="selectedRowIndex === i"
                  (click)="saveRow(i)"
                >
                  <i data-feather="save" class="text-success"></i>
                </a>
                <a
                  class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50"
                  *ngIf="selectedRowIndex === i"
                  (click)="cancelEdit(i)"
                >
                  <i data-feather="x-square" class="text-danger"></i>
                </a>
                <a
                  class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
                  *ngIf="selectedRowIndex !== i"
                  (click)="editData(i)"
                >
                  <i data-feather="edit" class="text-warning"></i>
                </a>
              </td>
            </tr>

            <tr>
              <td class="text-bold text-dark">
                {{ "General.Total" | translate }}
              </td>
              <td class="text-center text-bold text-dark active-pv">
                {{ getAPSum() }}
              </td>
              <td class="text-center text-bold text-dark master-pv">
                {{ getMPSum() }}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        {{ "BonusPlan.SystemBonusHistory" | translate }}
      </div>
      <div class="card-body" *ngIf="logDataList.length >= 1">
        <table class="table" datatable [dtOptions]="dtOptions">
          <thead>
            <tr>
              <th class="text-center" width="5%">#</th>
              <th width="30%">{{ "BonusPlan.Condition" | translate }}</th>
              <th class="text-center" width="15%">AP</th>
              <th class="text-center" width="15%">MP</th>
              <th class="text-center" width="15%">
                {{ "BonusPlan.UpdateBy" | translate }}
              </th>
              <th class="text-center" width="15%">
                {{ "BonusPlan.UpdateDate" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="w-100" *ngIf="isLoading">
              <td class="text-center" *ngFor="let num of [].constructor(6)">
                <div class="skeleton-loading"></div>
              </td>
            </tr>

            <tr *ngIf="logDataList.length < 1 && !isLoading">
              <td colspan="100%" class="text-center">
                <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
              </td>
            </tr>

            <tr *ngFor="let log of logDataList; index as ind">
              <td class="text-center">{{ ind + 1 }}</td>
              <td>
                <span *ngIf="log.conditionName === 'PresonalSV30K'" class="mr-1">
                  AP SV {{ log.apsvRate == '-' ? 0 : log.apsvRate}}+, MP SV
                {{ log.mpsvRate == '-' ? 0 : log.mpsvRate}}+
                </span>
                {{
                  "MLM.SystemBonusCondition." + log.conditionName | translate
                }}
              </td>
              <td class="text-center active-pv">
                {{ log.fromAPPercentage }}% ({{log.bonusName == "Downline Bonus" ? 'DS' : 'PS'}})
                <i data-feather="arrow-right" class="mx-1"></i>
                {{ log.toAPPercentage }}% ({{log.bonusName == "Downline Bonus" ? 'DS' : 'PS'}})
              </td>
              <td class="text-center master-pv">
                {{ log.fromMPPercentage }}% ({{log.bonusName == "Downline Bonus" ? 'DS' : 'PS'}})
                <i data-feather="arrow-right" class="mx-1"></i>
                {{ log.toMPPercentage }}% ({{log.bonusName == "Downline Bonus" ? 'DS' : 'PS'}})
              </td>
              <td class="text-center">
                {{ log.createBy ? log.createBy : "-" }}
              </td>
              <td class="text-center">
                <div *ngIf="currentLang != 'th'">
                  {{ log.createDate | date : "dd MMM YYYY HH:mm" }}
                </div>
                <div *ngIf="currentLang != 'en'">
                  {{ log.createDate | thaidate : "DD MMM YYYY HH:mm" }} น.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
