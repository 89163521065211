import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  ViewRef,
} from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../../dashboard.service';
import {
  BookingCalendarComponent,
  EventRef,
} from '../booking-calendar.component';
import { FlatpickrOptions } from 'ng2-flatpickr';
import Customer from 'app/main/model/Customer';
import Branch, { branchList } from 'app/main/model/Branch';
import { ApiService } from 'app/main/service/api.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ComponentsService } from 'app/main/components/components.service';
import { error } from 'console';
import {
  BookingPeriodTime,
  bookingPeriodTime,
  BookingStatusEnum,
  TimeOnly,
} from 'app/main/model/BookingPeriodTime';
import { EventClickArg } from '@fullcalendar/angular';
import { ApprovalModalComponent } from '../../booking-approval/approval-modal/approval-modal.component';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-booking-calendar-modal',
  templateUrl: './booking-calendar-modal.component.html',
  styleUrls: ['./booking-calendar-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BookingCalendarModalComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  @Input() title: string;
  @Input() eventRef: EventClickArg;
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();
  @Output() openDeleteModalFunc: EventEmitter<any> = new EventEmitter();
  @Input() isEditData: boolean;
  @Input() isWaitApproveEdit: boolean;
  @Input() isSubmit: boolean;
  @Input() isCreate: boolean;
  @Input() isDelete: boolean;
  @Input() isDataEmpty: boolean;
  @Input() bookingData: any;

  @ViewChild('startDatePicker') startDatePicker;
  searchData: any = {
    isDelete: false,
    isActive: true,
    SortPath: 'createDate',
  };
  public event: EventRef;

  public startDateOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'single',

    altInputClass: 'form-control flat-picker flatpickr-input',
    enableTime: false,
  };

  public startTimeOptions: FlatpickrOptions = this.createFlatpickrOptions();
  public endTimeOptions: FlatpickrOptions = this.createFlatpickrOptions();

  defaultDate: Date = new Date();

  bookingEnum = BookingStatusEnum;

  createFlatpickrOptions(): FlatpickrOptions {
    return {
      enableTime: true,
      time_24hr: true,
      // timeFormat: 'H:i',
      dateFormat: 'H:i',
      // format: 'H:i',
      noCalendar: true,
      // altInput: true,
      altFormat: 'H:i',
      // altInputClass: 'form-control flat-picker flatpickr-input',
      disabled: true,
      readOnly: true,
      allowInput: false,
      // pick12HourFormat: false
    };
  }

  public dateOptions: FlatpickrOptions = {
    // enableTime: true,
    time_24hr: true,
    timeFormat: 'H:i:ss',
    dateFormat: 'd/m/Y',
    noCalendar: false,
    // altInput: true,
    // altInputClass: 'form-control flat-picker flatpickr-input',
    disabled: true,
    allowInput: true,
    clickOpens: true,
    minDate: new Date(),
  };

  bookingPeriodTime: BookingPeriodTime[] = bookingPeriodTime;

  formGroup: FormGroup;

  colorList: any[];
  colorClicked: number;

  customerList: Customer[] = [];
  branchList: Branch[] = [];
  preparedData: any;

  editDataForm: FormGroup;

  formDisable: boolean = true;

  isBranchListLoading: boolean;
  isCustomerListLoading: boolean;

  isPast: boolean = false;

  selectedBranchId: string;
  selectedCustomerId: string;
  enumValue: number;
  periods: { value: number; label: string }[] = [
    { value: 1, label: '08:00 - 10:00' },
    { value: 2, label: '10:00 - 12:00' },
    { value: 3, label: '12:00 - 14:00' },
    { value: 4, label: '14:00 - 16:00' },
    { value: 5, label: '16:00 - 18:00' },
    { value: 6, label: '20:00 - 22:00' },
    { value: 0, label: 'Custom' },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private _calendarService: DashboardService,
    private _apiService: ApiService,
    private _modalService: NgbModal,
    private _componentsService: ComponentsService
  ) {
    this.colorList = [
      { id: 1, name: 'Strong cyan', colorCode: '#00B4D8' },
      { id: 2, name: 'Vivid blue', colorCode: '#1877F2' },
      { id: 3, name: 'Strong blue', colorCode: '#0048b9' },
      { id: 4, name: 'Dark blue', colorCode: '#19468D' },
      { id: 5, name: 'Dark violet', colorCode: '#3A0CA3' },
      { id: 6, name: 'Strong violet', colorCode: '#7209B7' },
      { id: 7, name: 'Vivid pink', colorCode: '#F72585' },
      { id: 8, name: 'Strong red', colorCode: '#D62828' },
      { id: 9, name: 'Orange', colorCode: '#F77F00' },
      { id: 10, name: 'Vivid orange', colorCode: '#FAA307' },
      { id: 11, name: 'Bright orange', colorCode: '#FCBF49' },
      { id: 12, name: 'Strong green', colorCode: '#7CB518' },
    ];

    this.getBranchList();
    this.formGroup = this._formBuilder.group({
      id: [''],
      branchId: ['', Validators.required],
      customerId: ['', Validators.required],
      topic: ['', [Validators.required]],
      date: [null, [Validators.required]],
      startDate: [null],
      endDate: [0],
      periodTimeEnum: [null, [Validators.required]],
      remarks: [''],
      stateEnum: [0],
      bookingHexColor: [0, Validators.required],
    });

    this.isCustomerListLoading = true;
    this._apiService
      .GetAllData('Customer', {
        isDelete: false,
        isActive: true,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.customerList = res.data.resultData;
        this.isCustomerListLoading = false;
      });
  }

  ngOnInit(): void {
    this._calendarService.onCurrentEventChange.subscribe((response) => {
      this.event = response;
      this.defaultDate = this.event.start;

      // If Event is available
      if (this.isEditData) {
        this.formGroup.disable();
        this.event = response;

        this.isDataEmpty = false;

        if (this.isWaitApproveEdit) {
          this.patchWaitApproveData(this.bookingData);
          this.dateOptions.defaultDate = new Date(this.bookingData.startDate);
        } else {
          this.patchEventValue(this.event);
        }
      }
      // else Create New Event
      if (!this.isEditData && !this.isCreate) {
        this.formGroup.reset();
        this.formDisable = false;

        const form = this.formGroup.value;
        this.formGroup.reset(form);
        this.dateOptions.defaultDate = this.event.start;

        this.formGroup.patchValue({
          date: this.event.start,
        });

        this.isDataEmpty = true;
      }
      if (this.isCreate && !this.isEditData) {
        this.formDisable = false;
        this.formGroup.reset();
        this.dateOptions.defaultDate = new Date(this.event.start);
        this.formGroup.controls['date'].setValue(this.event.start);
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  closeModal(): void {
    this.activeModal.close();
  }

  getBranchList() {
    this.isBranchListLoading = true;
    this._apiService
      .GetAllData('Branch?SortPath=createDate', this.searchData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.branchList = res.data.resultData;
        console.log(this.branchList);
        this.isBranchListLoading = false;
      });
  }
  get f() {
    return this.formGroup.controls;
  }

  onPeriodChange(event: any) {
    this.enumValue = this.toNumber(event.target.value);
    console.log(this.enumValue);
    if (this.enumValue == 0) {
      this.formGroup.get('startDate').enable();
      this.formGroup.get('startDate').setValidators([Validators.required]);
      this.formGroup.get('endDate').enable();
      this.formGroup.get('endDate').setValidators([Validators.required]);
      this.formGroup.get('periodTimeEnum').clearValidators();
    }
    this.formGroup.get('endDate').clearValidators();
    this.formGroup.get('startDate').clearValidators();
    this.formGroup.get('startDate').updateValueAndValidity();
    this.formGroup.get('endDate').updateValueAndValidity();
    // this.formGroup.get('date').updateValueAndValidity()
  }

  patchEventValue(event) {
    const startTime = new Date(event.extendedProps.startDate);
    startTime.setHours(startTime.getHours());

    const endTime = new Date(event.extendedProps.endDate);
    endTime.setHours(endTime.getHours());
    this.dateOptions.defaultDate = new Date(event.extendedProps.startDate);

    const dateTime = new Date(event.extendedProps.date);

    this.colorClicked = this.getColorIdByCode(
      this.event.extendedProps.bookingHexColor
    );

    this.startTimeOptions.defaultDate = startTime;
    this.endTimeOptions.defaultDate = endTime;
    this.dateOptions.defaultDate = dateTime;

    this.formGroup.patchValue({
      id: event.extendedProps.demonId,
      topic: event.title,
      date: new Date(event.extendedProps.startDate),
      startDate: new Date(startTime),
      endDate: new Date(endTime),
      periodTimeEnum: event.extendedProps.periodTimeEnum,
      remarks: event.extendedProps.remarks,
      branchId: event.extendedProps.branchId,
      customerId: event.extendedProps.customerId,
      bookingHexColor: event.extendedProps.bookingHexColor,
      stateEnum: event.extendedProps.stateEnum,
    });

    this.editDataForm = this.formGroup;

    const date = this.editDataForm.controls.id.value;
  }

  patchWaitApproveData(bookingData) {
    this.isPast = false;

    this.colorClicked = this.getColorIdByCode(bookingData.bookingHexColor);

    this.dateOptions.defaultDate = bookingData.startDate;
    this.startTimeOptions.defaultDate = bookingData.startDate;
    this.endTimeOptions.defaultDate = bookingData.endDate;

    this.formGroup.patchValue({
      id: bookingData.id,
      topic: bookingData.topic,
      date: new Date(bookingData.startDate),
      startDate: new Date(bookingData.startDate),
      endDate: new Date(bookingData.endDate),
      periodTimeEnum: bookingData.periodTimeEnum,
      remarks: bookingData.remarks,
      branchId: bookingData.branchId,
      customerId: bookingData.customerId,
      bookingHexColor: bookingData.bookingHexColor,
      stateEnum: bookingData.stateEnum,
    });

    this.editDataForm = this.formGroup;
    this.isCreate = false;
  }

  toNumber(string) {
    return Number(string);
  }

  openDeleteModal(event: EventRef) {
    this.openDeleteModalFunc.emit(event);
  }

  deleteEvent(id, remarks, stateEnum) {
    this._apiService
      .SetBookingEnumState('MeetingRoomBooking', id, { stateEnum, remarks })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          console.log(res);
          this._componentsService.SuccessSwal();
          this._modalService.dismissAll();
        },
        (error) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  prepareFinalData() {
    let startDate: Date;
    let endDate: Date;

    if (!this.isEditData && this.isCreate) {
      console.log('add naaaaaa');
      if (this.enumValue !== 0) {
        let selectedPeriod = this.bookingPeriodTime.find(
          (period) => period.Period === this.enumValue
        );

        if (selectedPeriod) {
          console.log(selectedPeriod);
          startDate = new Date(this.formGroup.controls.date.value);
          startDate.setHours(
            selectedPeriod.StartTime.hours,
            selectedPeriod.StartTime.minutes
          );

          endDate = new Date(this.formGroup.controls.date.value);
          endDate.setHours(
            selectedPeriod.EndTime.hours,
            selectedPeriod.EndTime.minutes
          );
        }
      } else if (this.enumValue === 0) {
        const date = this.formGroup.controls.date.value
          ? new Date(this.formGroup.controls.date.value)
          : null;
        const startTime = this.formGroup.controls.startDate.value
          ? new Date(this.formGroup.controls.startDate.value)
          : null;
        const endTime = this.formGroup.controls.endDate.value
          ? new Date(this.formGroup.controls.endDate.value)
          : null;

        if (date && startTime && endTime) {
          startDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            startTime.getHours(),
            startTime.getMinutes()
          );

          endDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            endTime.getHours(),
            endTime.getMinutes()
          );
        }
      }

      let kdate = moment(new Date(this.formGroup.controls.date.value))
        .utcOffset(0, true)
        .format();
      let kstartDate = moment(startDate).utcOffset(0, true).format();
      let kendDate = moment(endDate).utcOffset(0, true).format();

      const finalData = {
        ...this.formGroup.value,
        id: '',
        periodTimeEnum: this.enumValue,
        branchId: this.selectedBranchId,
        customerId: this.selectedCustomerId,
        remarks: this.formGroup.controls.remarks.value
          ? this.formGroup.controls.remarks.value
          : '',
        date: this.formGroup.controls.date.value
          ? new Date(this.formGroup.controls.date.value).toISOString()
          : null,
        startDate: startDate.toISOString(),
        stateEnum: 0,
        endDate: endDate.toISOString(),
      };
      const time = this.formGroup.controls.date.value;

      return finalData;
    } else {
      const enumEditValue = this.toNumber(
        this.editDataForm.controls.periodTimeEnum.value
      );
      const selectedPeriod = this.bookingPeriodTime.find(
        (period) => period.Period == enumEditValue
      );

      if (enumEditValue !== 0) {
        if (selectedPeriod) {
          startDate = new Date(this.editDataForm.controls.date.value);
          startDate.setHours(
            selectedPeriod.StartTime.hours,
            selectedPeriod.StartTime.minutes
          );

          endDate = new Date(this.editDataForm.controls.date.value);
          endDate.setHours(
            selectedPeriod.EndTime.hours,
            selectedPeriod.EndTime.minutes
          );
        }
      } else if (enumEditValue === 0) {
        const date = this.editDataForm.controls.date.value
          ? new Date(this.editDataForm.controls.date.value)
          : null;
        const startTime = this.editDataForm.controls.startDate
          ? new Date(this.editDataForm.controls.startDate.value)
          : null;

        const endTime = this.editDataForm.controls.endDate.value
          ? new Date(this.editDataForm.controls.endDate.value)
          : null;

        if (date && startTime && endTime) {
          startDate = new Date(
            date.getFullYear(),

            date.getMonth(),
            date.getDate(),
            startTime.getHours(),
            startTime.getMinutes()
          );

          endDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            endTime.getHours(),
            endTime.getMinutes()
          );
        }
      }

      let kdate = moment(new Date(this.editDataForm.controls.date.value))
        .utcOffset(0, true)
        .format();
      let kstartDate = moment(startDate).utcOffset(0, true).format();
      let kendDate = moment(endDate).utcOffset(0, true).format();

      const finalData = {
        ...this.editDataForm.value,
        id: this.editDataForm.controls.id.value,
        date: this.editDataForm.controls.date.value
          ? new Date(this.editDataForm.controls.date.value).toISOString()
          : null,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        stateEnum: this.editDataForm.controls.stateEnum.value,
        periodTimeEnum: enumEditValue,
      };
      console.log('Final update Data:', finalData);
      return finalData;
    }
  }
  selectColor(id: number) {
    if (this.formDisable) return;

    this.colorClicked = id;
    this.formGroup.patchValue({
      bookingHexColor:
        this.colorList.find((item) => item.id === id)?.colorCode || '#000000',
    });
    console.log(this.formGroup.value);
  }
  getColorIdByCode(colorCode: string): number {
    const color = this.colorList.find((item) => item.colorCode === colorCode);
    return color ? color.id : 0;
  }

  // Method to handle edit button click
  onEditButtonClick() {
    const currentDate = new Date();
    console.log('event date:', this.event.start);

    // Check if the event date is in the past or more than today
    if (this.formGroup.controls.startDate.value < currentDate) {
      this.isPast = true;
      this.formGroup.disable();
      // Event date is in the past
      // You can optionally display a message or handle the action accordingly
      console.log('Cannot edit events in the past.');
      return; // Prevent further execution
    }
    this.formDisable = false;
    this.formGroup.enable();
  }

  callBack(): void {
    this.preparedData = this.prepareFinalData();
    this.isSubmit = true;
    console.log(new Date(this.preparedData.startDate).getTime());
    const currentDate = new Date();
    if (this.formGroup.controls.date.value < currentDate) {
      this.isPast = true;
      return;
    }
    if (this.formGroup.invalid) {
      return;
    }
    this.isPast = false;

    console.log('Callback Final Data:', this.preparedData);
    console.log(this.isEditData);
    console.log(this.isCreate);

    this.callBackFunc.emit(this.preparedData);
  }

  onBranchChange(event: any): void {
    this.selectedBranchId = event;
  }
  onCustomerChange(event) {
    this.selectedCustomerId = event;
  }
}
