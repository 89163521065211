<div class="content-wrapper container-xxl p-0">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>

  <div class="content-body">
    <div class="card">
      <div class="card-body">
        <h4>Repove Code</h4>
        <hr />
        <div class="d-flex align-items-center">
          <span class="label">Repove Code</span>
          <input
            type="number"
            class="form-control w-25 mx-2"
            [ngClass]="{'is-invalid error' : isCodeError}"
            (change)="onValueChang($event)"
          />

          <div class="period-pick" ngbDropdown>
            <button ngbDropdownToggle aria-expanded="false" class="w-100">
              <span class="img">
                <img
                  src="../../../../assets/images/sv-bonus/icon/icon-calendar.svg"
                  alt=""
                />
              </span>
              <span class="text">{{ periodCodeS | date : "dd/MM/yyyy" }} -
                {{ periodCodeE | date : "dd/MM/yyyy" }}</span>
            </button>
            <ul class="dropdown-menu" ngbDropdownMenu>
              <li
                class="dropdown-item"
                ngbDropdownItem
                (click)="selectedPeriod(item.id, item.startDate, item.endDate)"
                *ngFor="let item of periodList"
              >
                {{ item.startDate | date : "dd/MM/yyyy" }} -
                {{ item.endDate | date : "dd/MM/yyyy" }}
              </li>
            </ul>
          </div>

          <button
            type="button"
            class="btn btn-primary ml-2"
            [disabled]="!isCanSubmitCode"
            (click)="submitCode()"
          >
            {{"Form.Submit" | translate}}
          </button>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h4>Excel File</h4>
        <hr />
        <div class="d-flex align-items-center">
          <span class="label">Export Template</span>
          <div class="w-25 mx-2">
            <button type="button" class="btn btn-primary" (click)="ExportXLSX()">{{"General.Download" | translate}}</button>
          </div>
        </div>
        <div class="d-flex align-items-center mt-1">
          <span class="label">Excel File</span>
          <div class="w-25 mx-2">
            <label for="excelFile"  class="form-control cursor-pointer file-excel">
              <div>Choose File</div>

            <span *ngIf="fileName">{{fileName}}</span>
            <span *ngIf="!fileName">ไม่ได้เลือกไฟล์</span>
            </label>
            <input
            id="excelFile"
              type="file"
              class="form-control cursor-pointer"
              hidden
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              (change)="onFileChange($event)"
              [ngClass]="{ 'is-invalid error': isFileError }"
            />
          </div>

          <div class="period-pick" ngbDropdown>
            <button ngbDropdownToggle aria-expanded="false" class="w-100">
              <span class="img">
                <img
                  src="../../../../assets/images/sv-bonus/icon/icon-calendar.svg"
                  alt=""
                />
              </span>
              <span class="text">{{ periodFileS | date : "dd/MM/yyyy" }} -
                {{ periodFileE | date : "dd/MM/yyyy" }}</span>
            </button>
            <ul class="dropdown-menu" ngbDropdownMenu>
              <li
                class="dropdown-item"
                ngbDropdownItem
                (click)="selectedPeriod(item.id, item.startDate, item.endDate)"
                *ngFor="let item of periodList"
              >
                {{ item.startDate | date : "dd/MM/yyyy" }} -
                {{ item.endDate | date : "dd/MM/yyyy" }}
              </li>
            </ul>
          </div>
          <button
            type="button"
            class="btn btn-primary ml-2"
            [disabled]="!isCanSubmitFile"
            (click)="submitFile()"
          >
            {{"Form.Submit" | translate}}
          </button>
        </div>

        <p *ngIf="isFileError" class="text-danger error-text">
              {{"iTem.Validate" | translate}} .xlsx
            </p>
      </div>
    </div>
  </div>
</div>
