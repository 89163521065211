import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ChartOptions } from '../../dashboard/executive-summary/executive-summary.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface SaleDashboardData {
  personalSummary?: SaleDashboardDetail;
  downline?: SaleDashboardDetail;
  system?: SaleDashboardDetail;
  total?: number;
  actualTotal?: number;
  voidValue?: number;
  yearSummary?: YearSummary[];
}

export interface SaleDashboardDetail {
  sv?: number;
  bonus?: number;
  percentage?: number;
  voidValue?: number;
  pssv?: number;
  dssv?: number;
  psBonus?: number;
  dsBonus?: number;
}

export interface YearSummary {
  year: number;
  quarter: number;
  result: number;
  voidValue?: number;
}

@Component({
  selector: 'app-customer-sale-dashboard',
  templateUrl: './customer-sale-dashboard.component.html',
  styleUrls: ['./customer-sale-dashboard.component.scss'],
})
export class CustomerSaleDashboardComponent implements OnInit, OnDestroy {
  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(),
  };

  startDate: any;
  endDate: any;

  public SVBonusChart: Partial<ChartOptions>;
  public RevenueChart: Partial<ChartOptions>;

  @ViewChild('SVBonusChartRef') SVBonusChartRef: any;
  @ViewChild('RevenueChartRef') RevenueChartRef: any;

  periodSV!: string;
  periodTeamS!: any;
  periodTeamE!: any;
  periodLast!: string;
  periodList!: any[];

  periodTeamObj = {
    downlineDetail: [],
    totalSV: 0,
    totalBonus: 0,
  };

  chartColors = {
    column: {
      personal: {
        sv: '#04B7FF',
        bonus: '#86DCFF',
      },
      downline: {
        sv: '#0269ED',
        bonus: '#549EFF',
      },
      system: {
        sv: '#023B95',
        bonus: '#2065D1',
      },
      quarter: ['#1877F2', '#0048B9', '#04B7FF', '#86DCFF'],

      bg: '#f8d3ff',
    },
  };

  chartData: SaleDashboardData;

  @BlockUI() blockUI: NgBlockUI;

  isCollapsed: boolean = true;
  customerId: string;

  personalBonusAlert: boolean = false;
  downlineBonusAlert: boolean = false;
  systemBonusAlert: boolean = false;

  private _unsubscribeAll = new Subject();

  constructor(
    private _coreConfigService: CoreConfigService,
    private _translateService: TranslateService,
    private _globalFuncService: GlobalFuncService,
    private _apiService: ApiService,
    private _route: ActivatedRoute
  ) {
    var self = this;
    this.SVBonusChart = {
      series: [],
      chart: {
        type: 'bar',
        height: 400,
        stacked: false,
        toolbar: {
          show: false,
        },
        fontFamily: 'Prompt, Montserrat, Helvetica, Arial, sans-serif',
      },
      grid: {
        strokeDashArray: 5,

        padding: {
          top: 50,
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          borderRadius: 10,
          borderRadiusApplication: 'end',
          dataLabels: {
            position: 'top',
            hideOverflowingLabels: false,
          },
        },
      },

      dataLabels: {
        style: {
          colors: ['#000000'],
          fontFamily: 'prompt',
          fontWeight: 400,
        },
        offsetY: -40,
        distributed: true,
        background: {
          enabled: true,
          borderRadius: 10,
          foreColor: '#ffffff',
          padding: 10,
          dropShadow: {
            enabled: false,
          },
        },
        formatter(val: number, opts) {
          return self._globalFuncService.FormatToMoney(val, false);
        },
      },
      xaxis: {
        categories: [
          'Personal Bonus',
          'Downline Bonus',
          'System Bonus PS',
          'System Bonus DS',
        ],
      },
      yaxis: {
        tickAmount: 6,
        labels: {
          formatter: function (val: number) {
            return self._globalFuncService.FormatToMoney(val);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return self._globalFuncService.FormatToMoney(val);
          },
        },
      },
    };

    this.RevenueChart = {
      series: [],
      chart: {
        type: 'bar',
        height: 300,
        stacked: false,
        toolbar: {
          show: false,
        },
        fontFamily: 'Prompt, Montserrat, Helvetica, Arial, sans-serif',
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          borderRadius: 10,
          borderRadiusApplication: 'end',
          dataLabels: {
            position: 'top',
            hideOverflowingLabels: false,
          },
        },
      },

      dataLabels: {
        style: {
          colors: ['#000000'],
          fontFamily: 'prompt',
          fontWeight: 400,
        },
        offsetY: -40,

        background: {
          enabled: true,
          borderRadius: 10,
          foreColor: '#ffffff',
          padding: 10,
          dropShadow: {
            enabled: false,
          },
        },
        formatter(val: number, opts) {
          return self._globalFuncService.FormatToMoney(val, false);
        },
      },
      colors: [this.chartColors.column.downline.bonus],

      xaxis: {
        categories: self.getQuarterList(),
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            return self._globalFuncService.FormatToMoney(val);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return self._globalFuncService.FormatToMoney(val);
          },
        },
      },
    };

    this._route.paramMap.subscribe((val) => {
      this.customerId = this._route.snapshot.paramMap.get('id');
    });
    this.getChartData();
    this.GetPeriodList();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    this.blockUI.start();

    this._coreConfigService.getConfig().subscribe((config) => {
      // If Menu Collapsed Changes
      if (
        config.layout.menu.collapsed === true ||
        config.layout.menu.collapsed === false
      ) {
        setTimeout(() => {
          // Get Dynamic Width for Charts

          this.SVBonusChart.chart.width =
            this.SVBonusChartRef?.nativeElement.offsetWidth;

          this.RevenueChart.chart.width =
            this.RevenueChartRef?.nativeElement.offsetWidth;

          this.blockUI.stop();
        }, 900);
      }
    });
  }

  getChartData() {
    // this.blockUI.start()
    let params: any = {};
    if (this.periodLast) {
      params.periodId = this.periodLast;
    }
    this._apiService
      .GetDataById('mlm/dashboard/customer', this.customerId, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.chartData = res.data.resultData[0];
        this.updateSVBonusSeries(this.chartData);
        this.blockUI.stop();
      });
  }

  getQuarterList(): string[] {
    let quarterTitleList: string[] = [];
    let currentDate = new Date();
    let currentQuarter = this.getQuarter(currentDate.getMonth());
    let currentYear = currentDate.getFullYear();

    for (let i = 0; i < 4; i++) {
      if (currentQuarter == 0) {
        currentQuarter = 4;
        currentYear--;
      }

      quarterTitleList.unshift(`Quarter ${currentQuarter}, ${currentYear}`);
      currentQuarter--;
    }
    return quarterTitleList;
  }

  getQuarter(month: number): number {
    return Math.floor((month % 12) / 3) + 1;
  }

  updateSVBonusSeries(chartData: SaleDashboardData) {
    this.personalBonusAlert = chartData.personalSummary.voidValue > 0;
    this.downlineBonusAlert = chartData.downline.voidValue > 0;
    this.systemBonusAlert = false;

    console.log(chartData);
    this.SVBonusChart.series = [
      {
        name: 'SV',
        data: [
          {
            x: 'Personal Bonus',
            y: chartData.personalSummary.sv,
            fillColor: this.chartColors.column.personal.sv,
            columnWidthOffset: -10,
          },
          {
            x: 'Downline Bonus',
            y: chartData.downline.sv,
            fillColor: this.chartColors.column.downline.sv,
            columnWidthOffset: -10,
          },
          {
            x: 'System Bonus PS',
            y: chartData.system.pssv,
            fillColor: this.chartColors.column.system.sv,
            columnWidthOffset: -10,
          },
          {
            x: 'System Bonus DS',
            y: chartData.system.dssv,
            fillColor: this.chartColors.column.system.sv,
            columnWidthOffset: -10,
          },
        ],
      },
      {
        name: 'Baht',
        data: [
          {
            x: 'Personal Bonus',
            y:
              chartData.personalSummary.bonus -
              chartData.personalSummary.voidValue,
            fillColor: this.chartColors.column.personal.bonus,
            columnWidthOffset: -10,
          },
          {
            x: 'Downline Bonus',
            y: chartData.downline.bonus - chartData.downline.voidValue,
            fillColor: this.chartColors.column.downline.bonus,
            columnWidthOffset: -10,
          },
          {
            x: 'System Bonus PS',
            y: chartData.system.psBonus,
            fillColor: this.chartColors.column.system.bonus,
            columnWidthOffset: -10,
          },
          {
            x: 'System Bonus DS',
            y: chartData.system.dsBonus,
            fillColor: this.chartColors.column.system.bonus,
            columnWidthOffset: -10,
          },
        ],
      },
    ];

    this.RevenueChart.series = [
      {
        name: 'Revenue',
        data: [
          {
            x: `Quarter ${chartData.yearSummary[3].quarter}\n${chartData.yearSummary[3].year}`,
            y:
              chartData.yearSummary[3].result -
              chartData.yearSummary[3].voidValue,
            fillColor: this.chartColors.column.quarter[0],
            columnWidthOffset: -5,
          },
          {
            x: `Quarter ${chartData.yearSummary[2].quarter}\n${chartData.yearSummary[2].year}`,
            y:
              chartData.yearSummary[2].result -
              chartData.yearSummary[2].voidValue,
            fillColor: this.chartColors.column.quarter[1],
            columnWidthOffset: -5,
          },
          {
            x: `Quarter ${chartData.yearSummary[1].quarter}\n${chartData.yearSummary[1].year}`,
            y:
              chartData.yearSummary[1].result -
              chartData.yearSummary[1].voidValue,
            fillColor: this.chartColors.column.quarter[2],
            columnWidthOffset: -5,
          },
          {
            x: `Quarter ${chartData.yearSummary[0].quarter}\n${chartData.yearSummary[0].year}`,
            y:
              chartData.yearSummary[0].result -
              chartData.yearSummary[0].voidValue,
            fillColor: this.chartColors.column.quarter[3],
            columnWidthOffset: -5,
          },
        ],
      },
    ];
  }

  search() {
    // this.blockUI.start();
    // console.log(this.startDate);
    // console.log(this.endDate);
    this.getChartData();
    // setTimeout(() => {
    //   this.blockUI.stop();
    // }, 2000);
  }

  GetPeriodList(): void {
    this._apiService
      .GetAllData('mlm/SalePeriod', { Direction: 1 })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.periodList = res.data.resultData;
        this.periodTeamS = res.data.resultData[0].startDate;
        this.periodTeamE = res.data.resultData[0].endDate;
      });
  }

  selectedPeriod(id: any, start: any, end: any) {
    this.periodTeamS = start;
    this.periodTeamE = end;
    this.periodLast = id;
  }
}
