import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventBonusManageComponent } from './event-bonus-manage.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { DataTablesModule } from 'angular-datatables';
import { CoreDirectivesModule } from '@core/directives/directives';
import { TranslateModule } from '@ngx-translate/core';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const path: string = 'mlm-config/event-bonus-config';
const routes: Routes = [
  {
    path: path,
    component: EventBonusManageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'event-bonus-config' },
  },
];

@NgModule({
  declarations: [EventBonusManageComponent],
  imports: [
    CommonModule,
    CoreCommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ContentHeaderModule,
    DataTablesModule,
    CoreDirectivesModule,
    TranslateModule,
    NgbModule,
  ],
})
export class EventBonusModule {}
