<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <app-search-panel [forCompanentName]="componentName" [pathUrl]="pathUrl + '/create'" [isGridView]="isGridView"
      [isForGroupTemplate]="isForGroupTemplate" [isShowSortByGroup]="true" [isShowSubCategory]="true"
      [loadAllSubCategoryData]="true" (listView)="listView()" (gridView)="gridView()" (SetLoading)="SetLoadingState()"
      (exportXLSX)="exportXLSX()" (callDataFromAPI)="initData($event)" [isLoading]="isLoading"></app-search-panel>

    <!-- <h2 class="content-header-title mb-2">Category</h2> -->

    <section>
      <div [ngClass]="{ 'd-none': isGridView }">
        <app-content-list [apiPath]="apiPath" [searchData]="searchData" [mainPathUrl]="pathUrl + '/detail'"
          [editPathUrl]="pathUrl + '/edit'" [page]="page" [pageSize]="pageSize" (deleteFunc)="deleteContent($event)"
          (isActiveFunc)="setActiveContent($event)" (SetLoadingStateFunc)="SetLoadingStateFunc($event)"
          [isGoEditPage]="isGoEditPage" [pathExcel]="'Excel/ExportClass'"
          [fileNameExcel]="'Class Report'"></app-content-list>
      </div>
    </section>
  </div>
</div>
