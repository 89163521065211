import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { ApiService } from 'app/main/service/api.service';
import { rejects } from 'assert';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-system-bonus-table',
  templateUrl: './system-bonus-table.component.html',
  styleUrls: ['./system-bonus-table.component.scss'],
})
export class SystemBonusTableComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  contentHeader: ContentHeader;

  systemBonusDataList: SystemBonusData[] = [];
  originalSystemBonusDataList: SystemBonusData[] = [];
  logDataList: SystemBonusLog[] = [];
  selectedRowIndex: number = -1;

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  pageSize: number = 10;
  page: number = 1;

  @BlockUI() blockUI: NgBlockUI;

  // systemBonus: any[] = [
  //   {
  //     condition: 'ยอดขายส่วนบุคคล 30,000+ SV ใน 1 Period',
  //     apPoint: 2,
  //     mpPoint: 2,
  //   },
  //   {
  //     condition: 'เข้าร่วม 100%',
  //     apPoint: 2,
  //     mpPoint: 2,
  //   },
  //   {
  //     condition: 'MP Bonus',
  //     apPoint: 0,
  //     mpPoint: 2,
  //   },
  // ];

  currentLang: string = '';
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.initData()
      .then(() => {
        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: this.pageSize,
          lengthMenu: [10, 50, 100],
          processing: true,
          paging: true,
          lengthChange: true,
          searching: false,
          ordering: false,
          info: true,
          autoWidth: false,
          responsive: true,
          deferLoading: 10,
        };
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });

    this.contentHeader = {
      headerTitle: 'BonusPlan.SystemBonus',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  initData(): Promise<void> {
    this.blockUI.start();

    let promise1 = this.getSystemBonusData().then((res) => {
      this.systemBonusDataList = res.data.resultData.map((item) => ({
        ...item,
      }));
      this.originalSystemBonusDataList = res.data.resultData.map((item) => ({
        ...item,
      }));
      this.systemBonusDataList.sort((a, b) => a.id - b.id);
      this.originalSystemBonusDataList.sort((a, b) => a.id - b.id);

      const newList = this.systemBonusDataList.map((item: any) => {
        return {
          ...item,
          dsApSelect:
            item.apSelectedBonus == 2 || item.apSelectedBonus == 3
              ? true
              : false,
          psApSelect:
            item.apSelectedBonus == 1 || item.apSelectedBonus == 3
              ? true
              : false,
          dsMpSelect:
            item.mpSelectedBonus == 2 || item.mpSelectedBonus == 3
              ? true
              : false,
          psMpSelect:
            item.mpSelectedBonus == 1 || item.mpSelectedBonus == 3
              ? true
              : false,
        };
      });

      this.systemBonusDataList = newList;
    });

    let promise2 = this.getSystemBonusLogData().then((res) => {
      this.logDataList = res.data.resultData;
    });

    return Promise.all([promise1, promise2])
      .then(() => {
        this.blockUI.stop();
      })
      .catch((error) => {
        console.error('Error initializing data:', error);
        this.blockUI.stop();
      });
  }

  getSystemBonusData(): Promise<CommonResponse<SystemBonusData>> {
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData('mlm/MasterSystemBonus').subscribe(
        (res: CommonResponse<SystemBonusData>) => {
          resolve(res);
        },
        (err) => {
          rejects();
        }
      );
    });
  }

  getSystemBonusLogData(): Promise<CommonResponse<SystemBonusLog>> {
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData('mlm/SystemLog', { direction: 1 }).subscribe(
        (res: CommonResponse<SystemBonusLog>) => {
          resolve(res);
        },
        (err) => {
          rejects();
        }
      );
    });
  }

  saveSystemBonusData(
    id: number,
    data: {
      apPercentage: number;
      mpPercentage: number;
    }
  ): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .UpdateDataById('mlm/MasterSystemBonus', id.toString(), data)
        .subscribe(
          (res) => {
            resolve();
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  saveData(index: number) {
    this.blockUI.start();

    const obj = this.systemBonusDataList[index];

    const body = {
      apsvRate: obj.apsvRate ? parseFloat(obj.apsvRate.toString()) : 0,
      mpsvRate: obj.mpsvRate ? parseFloat(obj.mpsvRate.toString()) : 0,
      apPercentage: obj.apPercentage
        ? parseFloat(obj.apPercentage.toString())
        : 0,
      dsapPercentage: obj.dsapPercentage
        ? parseFloat(obj.dsapPercentage.toString())
        : 0,
      mpPercentage: obj.mpPercentage
        ? parseFloat(obj.mpPercentage.toString())
        : 0,
      dsmpPercentage: obj.dsmpPercentage
        ? parseFloat(obj.dsmpPercentage.toString())
        : 0,
      apSelectedBonus:
        obj.psApSelect && obj.dsApSelect
          ? 3
          : !obj.psApSelect && obj.dsApSelect
          ? 2
          : obj.psApSelect && !obj.dsApSelect
          ? 1
          : 0,
      mpSelectedBonus:
        obj.psMpSelect && obj.dsMpSelect
          ? 3
          : !obj.psMpSelect && obj.dsMpSelect
          ? 2
          : obj.psMpSelect && !obj.dsMpSelect
          ? 1
          : 0,
    };

    // console.log('body', body);

    this.saveSystemBonusData(this.systemBonusDataList[index].id, body)
      .then(() => {
        this.cancelEdit();
        this.initData();
        this.blockUI.stop();
        this._componentsService.SuccessSwal();
      })
      .catch(() => {
        this.cancelEdit();
        this.blockUI.stop();
        this._componentsService.ErrorSwal();
      });
  }

  editData(index: number) {
    this.selectedRowIndex = index;
  }

  isSave = false;

  saveRow(index: number) {
    if (this.selectedRowIndex === index) {
      this.isSave = true;
      const obj = this.systemBonusDataList[index];

      if (
        obj.apPercentage != null &&
        obj.dsapPercentage != null &&
        obj.mpPercentage != null &&
        obj.dsmpPercentage != null
      ) {
        if (
          obj.conditionName !== 'PresonalSV30K'
            ? true
            : obj.apsvRate >= 0 && obj.mpsvRate >= 0
        ) {
          const modalRef = this._modalService.open(ModalComponent, {
            centered: true,
          });

          modalRef.componentInstance.title = this._translateService.instant(
            'Modal.ConfirmCreate'
          );
          modalRef.componentInstance.detail = this._translateService.instant(
            'Modal.AreYouSureToCreate'
          );
          modalRef.componentInstance.callBackFunc.subscribe(() => {
            this.saveData(index);
          });
          modalRef.componentInstance.cancelFunc.subscribe(() => {
            this.cancelEdit();
          });
        }
      }
    }
  }

  onDataChange() {}

  cancelEdit() {
    this.selectedRowIndex = -1;
    this.systemBonusDataList = this.originalSystemBonusDataList.map((item) => ({
      ...item,
      dsApSelect:
        item.apSelectedBonus == 1 || item.apSelectedBonus == 3 ? true : false,
      psApSelect:
        item.apSelectedBonus == 2 || item.apSelectedBonus == 3 ? true : false,
      dsMpSelect:
        item.mpSelectedBonus == 1 || item.mpSelectedBonus == 3 ? true : false,
      psMpSelect:
        item.mpSelectedBonus == 2 || item.mpSelectedBonus == 3 ? true : false,
    }));
  }

  deleteData(index: number) {
    this.systemBonusDataList.splice(index, 1);
  }

  getAPSum() {
    let resultPS = 0;
    let resultDS = 0;

    this.systemBonusDataList.forEach((bonus) => {
      if (
        bonus.psApSelect ||
        bonus.apSelectedBonus == 1 ||
        bonus.apSelectedBonus == 3
      ) {
        resultPS += parseFloat(bonus.apPercentage.toString());
      }

      if (
        bonus.dsApSelect ||
        bonus.apSelectedBonus == 2 ||
        bonus.apSelectedBonus == 3
      ) {
        resultDS += parseFloat(bonus.dsapPercentage.toString());
      }
    });

    return resultPS.toFixed(2) + '% (PS) ' + resultDS.toFixed(2) + '% (DS) ';
  }

  getMPSum() {
    let resultPS = 0;
    let resultDS = 0;

    this.systemBonusDataList.forEach((bonus) => {
      if (
        bonus.psMpSelect ||
        bonus.mpSelectedBonus == 1 ||
        bonus.mpSelectedBonus == 3
      ) {
        resultPS += parseFloat(bonus.mpPercentage.toString());
      }

      if (
        bonus.dsMpSelect ||
        bonus.mpSelectedBonus == 2 ||
        bonus.mpSelectedBonus == 3
      ) {
        resultDS += parseFloat(bonus.dsmpPercentage.toString());
      }
    });

    return resultPS.toFixed(2) + '% (PS) ' + resultDS.toFixed(2) + '% (DS) ';
  }
}

export interface SystemBonusData {
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
  id: number;
  conditionName: string;
  apsvRate: number;
  mpsvRate: number;
  apPercentage: string | number;
  dsapPercentage: string | number;
  mpPercentage: string | number;
  dsmpPercentage: string | number;
  sv: string | number;
  apSelectedBonus: number;
  mpSelectedBonus: number;
  psApSelect?: boolean;
  dsApSelect?: boolean;
  psMpSelect?: boolean;
  dsMpSelect?: boolean;
}

export interface SystemBonusLog {
  isActive: boolean;
  isDelete: boolean;
  direction: number;
  sortPath: string;
  pageLength: number;
  page: number;
  status: string;
  saleInterval: number;
  fromAPPercentage: number;
  toAPPercentage: number;
  fromMPPercentage: number;
  toMPPercentage: number;
  fromPPercentage: number;
  toPPercentage: number;
}
