import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ContentListComponent } from 'app/main/components/content-list/content-list.component';
import { ApiService } from 'app/main/service/api.service';
import { ComponentsService } from 'app/main/components/components.service';
import { CustomerService } from './customer.service';
import Customer from 'app/main/model/Customer';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import Content from 'app/main/model/Content';
import { FilesApiService } from 'app/main/service/files-api.service';

@Component({
  selector: 'app-content-template',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;

  componentName: string;
  apiPath: string;
  pathUrl: string;
  keyName: string;
  editPathUrl: string;
  detailPathUrl: string;

  contentHeader: object;
  itemList: Customer[];

  isLoading: boolean;
  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;

  activeStatus: string;
  searchText: string;

  searchByName: number = 0;

  public page = 1;
  public pageSize = 10;

  currentLang: string;
  searchData: any = {};

  @ViewChild(ContentListComponent) contentListTable: ContentListComponent;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('searchbar') searchBar: ElementRef;
  @ViewChild('activeFil') activeFil: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private _customerService: CustomerService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal,
    private _fileApiService: FilesApiService
  ) {
    this.SetLoadingState();
    this.initConfig();
    this.initData({ SortPath: 'CreateDate', Direction: 1 });
  }

  initConfig(): void {
    this.componentName = this._customerService.componentName;
    this.apiPath = this._customerService.apiUrl;
    this.pathUrl = this._customerService.pathUrl;
    this.keyName = this._customerService.keyName;
    this.editPathUrl = this._customerService.editPathURL;
    this.detailPathUrl = this._customerService.detailPathURL;
  }

  initData(obj?: { [key: string]: any }): void {
    this.SetLoadingState();
    this.itemList = [];

    this.searchData = {
      SortPath: this.apiPath + 'Code',
      Direction: 1,
      isDelete: false,
    };

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          SortPath: this.keyName,
          Direction: 1,
          isDelete: false,
        };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = {
        SortPath: this.keyName,
        Direction: 1,
        isDelete: false,
      };
    }

    if (this.searchByName == 0) {
      this.searchData.Detail = this.searchText;
    } else {
      this.searchData.CustomerCode = this.searchText;
    }

    this.loadData(this.searchData);
  }

  ResetSearch() {
    this.searchByName = 0;
    this.searchBar.nativeElement.value = '';
    this.activeFil.nativeElement.value = 'all';
    this.initData();
  }

  SubmitSearch() {
    if (this.isLoading) return;

    let params: any = {};
    const activeStr = this.activeFil.nativeElement.value;
    if (activeStr === 'active') {
      params.isActive = true;
    } else if (activeStr === 'inactive') {
      params.isActive = false;
    }

    let obj = {
      searchText: this.searchBar.nativeElement.value
        ? this.searchBar.nativeElement.value
        : '',
      params: params,
    };

    this.initData(obj);
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  listView() {
    this.isGridView = false;
    this.contentListTable.refreshDataTable();
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val;
    this.SetLoadedState();
  }

  setPageSize(val: number): void {
    this.pageSize = val;
  }

  ActiveStatusFilter(val: string) {
    this.activeStatus = val;
  }

  SortFilter(key: string) {
    var self = this;

    let objKey = JSON.parse(key);
    let SortPath = objKey.SortPath;
    let Direction = objKey.Direction;

    this.SetLoadingState();
    this.initData({
      SortPath: SortPath,
      Direction: Number(Direction),
    });
    // this.dataSubscription = this._apiService
    //   .GetAllData(this.apiPath, {
    //     SortPath: SortPath,
    //     Direction: Number(Direction),
    //   })
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe((res) => {
    //     self.itemList = res.data.resultData;
    //     self.SetLoadedState();
    //   });
  }

  exportXLSX() {
    this.contentListTable.ExportXLSX();
  }

  setActiveContent(val): void {
    var self = this;
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .subscribe(
        (res) => {
          self._componentsService.SuccessSwal();
          self.SetLoadingState();
          self.initData();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
  }

  openIsActiveModal(itemObj, status): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    let modeText = status
      ? this._translateService.instant('Status.Active')
      : this._translateService.instant('Status.Inactive');
    modalRef.componentInstance.title = `${modeText}`;
    modalRef.componentInstance.detail = `Are you confirm to ${modeText} ${itemObj.username}`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.setActiveContent({ id: itemObj.id, status: status });
    });
  }

  deleteContent(guid: string): void {
    var self = this;
    this._apiService.SoftDelete(this.apiPath, guid).subscribe(
      (res) => {
        self._componentsService.SuccessSwal();
        self.SetLoadingState();
        self.initData();
      },
      (err) => {
        self._componentsService.ErrorSwal();
      }
    );
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
    } else {
      this.searchData = {
        SortPath: this.apiPath + 'Code',
        Direction: 1,
        isDelete: false,
      };
    }

    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }

  ngOnInit(): void {
    var self = this;
    this.contentHeader = {
      headerTitle: 'General.Customer',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[2, 'asc']],
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'CustomerCode', targets: 1 },
        { name: 'Detail', targets: 2 },
        { name: 'ContactEmail', targets: 3 },
        { name: 'IsActive', targets: 4 },
        { orderable: false, targets: 5 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.itemList = [];
        self.SetLoadingState();

        let defaultSortName = 'BusinessName';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        console.log(this.searchData);
        this._apiService
          .GetAllData(this.apiPath, this.searchData)
          .subscribe((response) => {
            console.log(response);
            this.itemList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  ExportXLSX() {
    this._fileApiService.getExcelReport(
      'Excel/ExportCustomer',
      'Customer Report',
      this.searchData
    );
  }
}
