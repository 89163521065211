import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import Customer from 'app/main/model/Customer';
import { CustomerService } from 'app/main/pages/customer/customer.service';
import { ApiService } from 'app/main/service/api.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-downline-detail',
  templateUrl: './downline-detail.component.html',
  styleUrls: ['./downline-detail.component.scss'],
})
export class DownlineDetailComponent implements OnInit {
  downlineList: Customer[] = [];
  newUsersList: Customer[] = [];
  topWeekCustomerList: Customer[] = [];
  topMonthCustomerList: Customer[] = [];

  private _unsubscribeAll: Subject<any> = new Subject();
  dataSubscription: Subscription;

  searchData: any = {};

  detailPathURL: string;
  customerId: string;

  public page = 1;
  public pageSize = 25;

  public rows;

  totalSV: number = 0;
  totalBonus: number = 0;

  periodSV!: string;
  periodTeamS!: any;
  periodTeamE!: any;
  periodLast!: string;
  periodList!: any[];

  periodTeamObj = {
    downlineDetail: [],
    totalSV: 0,
    totalBonus: 0,
  };

  sortSV: number = 1;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  isLoading: boolean = false;
  isVerify: boolean = false;

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(),
  };

  constructor(
    private _apiService: ApiService,
    private _customerService: CustomerService,
    private _route: ActivatedRoute,
    private _modalService: NgbModal
  ) {
    this.detailPathURL = this._customerService.detailPathURL;

    this._route.paramMap.subscribe((val) => {
      this.customerId = this._route.snapshot.paramMap.get('id');
      this.GetTopWeekCustomer();
      this.GetTopMonthCustomer();
      this.GetNewMember();
      this.GetPeriodList();
      this.GetTotalBonus();
    });
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  GetNewMember(): void {
    this._apiService
      .GetDataById('mlm/dashboard/team/newmember/customer', this.customerId)
      .subscribe(
        (res) => {
          this.newUsersList = res.data.resultData;
        },
        (err) => {}
      );
  }

  GetTopWeekCustomer(): void {
    this._apiService
      .GetDataById(
        'mlm/dashboard/team/TopWeekCustomerReward/customer',
        this.customerId
      )
      .subscribe(
        (res) => {
          this.topWeekCustomerList = res.data.resultData;
        },
        (err) => {}
      );
  }

  GetTopMonthCustomer(): void {
    this._apiService
      .GetDataById(
        'mlm/dashboard/team/TopMonthCustomerReward/customer',
        this.customerId
      )
      .subscribe(
        (res) => {
          this.topMonthCustomerList = res.data.resultData;
        },
        (err) => {}
      );
  }

  GetTotalBonus(): void {
    this._apiService
      .GetDataById('mlm/dashboard/team/totalBonus/customer', this.customerId)
      .subscribe((res) => {
        this.totalSV = res.data.resultData[0].totalSV;
        this.totalBonus = res.data.resultData[0].totalBonus;
      });
  }

  teamPeriod(id: string, start: string, end: string) {
    this.periodTeamS = start;
    this.periodTeamE = end;
    this.downlineList = [];
    this.periodLast = id;
    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          console.log('period');
        });
      });
    }
  }

  GetPeriodList(): void {
    this._apiService
      .GetAllData('mlm/SalePeriod', { Direction: 1 })
      .subscribe((res) => {
        this.periodList = res.data.resultData;
        this.periodTeamS = res.data.resultData[0].startDate;
        this.periodTeamE = res.data.resultData[0].endDate;

        this.selectSort(this.sortSV);
      });
  }

  selectSort(direction: number) {
    this.downlineList = [];
    this.sortSV = direction;
    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          console.log('sort');
        });
      });
    }
  }

  ngOnInit(): void {
    var self = this;
    this.getTeam();
  }

  getTeam() {
    var self = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: 10,
      order: [[1, 'asc']],
      ordering: false,
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'Name', targets: 1 },
        { name: 'Status', targets: 2 },
        { name: 'IsActive', targets: 3 },
        { orderable: false, targets: 4 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        self.SetLoadingState();
        self._apiService
          .GetAllData(`mlm/dashboard/team/customer/${this.customerId}`, {
            SalePeriodId: self.periodLast ? self.periodLast : '',
            IsActive: true,
            IsDelete: false,
            Direction: self.sortSV,
            SortPath: 'sv',
            PageLength: 10,
            Page: Math.ceil(
              (dataTablesParameters.start + 1) / dataTablesParameters.length
            ),
          })
          .subscribe(
            (res) => {
              this.downlineList = res.data.resultData;
              self.SetLoadedState();

              callback({
                recordsTotal: 10,
                recordsFiltered: res.data.totalItem,
                data: [],
              });
            },
            (err) => {
              self.SetLoadedState();
            }
          );
      },
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  getCustomerProvice() {
    // this._
  }

  openModal(modal: any) {
    this._modalService.open(modal, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
  }
}
