import { NgModule } from '@angular/core';
import { BonusTableModule } from './bonus-table/bonus-table.module';
import { SystemBonusModule } from './system-bonus-table/system-bonus.module';
import { DownlineBonusModule } from './downline-bonus-table/downline-bonus.module';
import { MlmConfigurationService } from './mlm-configuration.service';
import { ContentHeaderModule } from '../../../layout/components/content-header/content-header.module';
import { SvExchangeRateModule } from './sv-exchange-rate/sv-exchange-rate.module';
import { RankManageModule } from './rank-manage/rank-manage.module';
import { SalePeriodManageModule } from './sale-period-manage/sale-period-manage.module';
import { EventBonusModule } from './event-bonus-manage/event-bonus.module';


@NgModule({
  declarations: [],
  providers: [MlmConfigurationService],
  imports: [
    BonusTableModule,
    SystemBonusModule,
    DownlineBonusModule,
    ContentHeaderModule,
    SvExchangeRateModule,
    RankManageModule,
    SalePeriodManageModule,
    EventBonusModule,
  ],
})
export class MLMConfigurationModule {}
