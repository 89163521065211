import { CurrencyPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  QueryList,
  Input,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  ChangeDetectorRef,
  AfterViewChecked,
  Renderer2,
} from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { ComponentsService } from 'app/main/components/components.service';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import Customer from 'app/main/model/Customer';
import Item from 'app/main/model/Item';
import {
  Quotation,
  QuotationItem,
  TempRowQuotation,
} from 'app/main/model/Quotation';
import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { DragulaService } from 'ng2-dragula';
import { FlatpickrOptions } from 'ng2-flatpickr';

import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';

import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { DocumentService } from 'app/main/pages/document/document.service';
import { QuatationService } from 'app/main/pages/document/quotation/quotation.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { DocumentStatus } from 'app/main/model/DocumentStatus';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { takeUntil } from 'rxjs/operators';
import { StockType, kStockType } from 'app/main/model/StockType';
import { MainConfig } from 'app/main/config/MainConfig';
import flatpickr from 'flatpickr';
import moment from 'moment';

@Component({
  selector: 'app-add-bill',
  templateUrl: './add-bill.component.html',
  styleUrls: ['./add-bill.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddBillComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  @Input() data!: any;
  @Input() isEditing: Boolean;
  @Input() isEditForCreating: Boolean;
  @Input() isOnlyDecrease: Boolean;
  @Input() hideSubmit: Boolean;
  @Input() docTitle: string;
  @Input() docCodeColumn: string;
  @Input() isSellerDoc: boolean = false;
  @Input() isBuyerDoc: boolean = false;
  @Input() isQuotation: boolean = false;
  @Input() isCreditDebitMode: boolean = false;
  @Input() isCreditNote: boolean = false;
  @Input() isDebitNote: boolean = false;
  @Input() isCreateInvoice: boolean = false;
  @Input() isCreateGR: boolean = false;
  @Input() isPurchaseRequest: boolean = false;
  @Input() isDateChange: boolean = true;
  @Input() documentCode: string;
  @Input() isUseValidDate: boolean = true;

  @BlockUI() blockUI: NgBlockUI;

  @Output() submitData: EventEmitter<any> = new EventEmitter<any>();
  @Output() getFormData: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('confirmModal') confirmModal: ModalComponent;
  @ViewChildren('formTable') formTable: QueryList<ElementRef<HTMLLIElement>>;
  @ViewChildren('footerForm') footerForm: QueryList<ElementRef<HTMLLIElement>>;

  @ViewChild('createDate') createDate;
  @ViewChild('expriedDate') expriedDate;

  copyObj: any;

  private _unsubscribeAll: Subject<any> = new Subject();

  maxLengthOfValue: number;

  contentHeader: object;
  currentUser: User;
  @Input() apiPath: string;
  @Input() path: string;
  invoiceApiPath: string;
  idDocument: string;
  docID: string;
  refID: string;
  docCode: string;
  documentRef: string;
  isSubmit: boolean;
  quotationForm: FormGroup;
  quotationObj: Quotation;
  showSubmitBtn: Boolean;
  taxRate: number = 7.0;
  whtRate: number = 0;
  refOldPrice: number = 0;
  oldGrandTotal: number = 0;
  componentName: String;
  itemCounter: number = 0;
  rows: TempRowQuotation[] = [];
  draggingRow: any;
  amount: any;
  isDateError: Boolean = false;
  originalData: TempRowQuotation[] = [];
  workingData: TempRowQuotation[] = [];

  itemDefault: any;
  quantityStockDefault: number[] = [];
  quantityItemDefault: number[] = [];
  unitPriceItemDefault: number[] = [];
  discountItemDefault: number[] = [];
  grandTotalItemDefault: number[] = [];
  totalDiscountDefault: number = 0;
  shippingFeeDefault: number = 0;
  discountTypeDefault: boolean;

  getPreTaxAmount: number[] = [];

  numQuantityArray: number[] = [];
  numUnitPriceArray: number[] = [];
  numDiscountArray: number[] = [];
  tempNetAmountArray: number[] = [];

  itemInvalid: boolean[] = [];
  descriptionInvalid: boolean = false;
  invalidDescriptionRows: number[] = [];
  dateInvalid: boolean = false;
  quantityInvalid: boolean[] = [];
  quantityWarning: boolean[] = [];
  unitPriceInvalid: boolean[] = [];
  discountInvalid: boolean[] = [];
  totalDiscountInvalid: boolean = false;
  sumTotalInvalid: boolean = false;
  @Output() itemInvalidChange: EventEmitter<boolean[]> = new EventEmitter<
    boolean[]
  >();
  @Output() sumTotalInvalidChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() descriptionInvalidChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() totalDiscountInvalidChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  selectedTaxType: boolean = false;

  currentDate: Date = new Date();
  nextWeekDate: Date = new Date();

  primaryObj: TempRowQuotation[];

  customerList: Customer[] = [];
  customerObj: Customer;
  customerAddressObj: CustomerAddress;
  shippingAddressList: CustomerAddress[];
  customerAddressList: CustomerAddress[];
  itemList: Item[] = [];

  shippingAddressId: string;
  shippingAddressDetail: string;
  shippingPhone: string;
  shippingCustomerName: string;
  shippingCustomerTaxId: string;

  taxInvoiceAddressId: string;
  taxInvoiceAddressDetail: string;
  taxInvoicePhone: string;
  taxInvoiceCustomerName: string;
  taxInvoiceCustomerTaxId: string;

  defaultShippingAddressId: string;
  defaultShippingAddressDetail: string;
  defaultShippingPhone: string;
  defaultShippingCustomerName: string;
  defaultShippingCustomerTaxId: string;

  defaultTaxInvoiceAddressId: string;
  defaultTaxInvoiceAddressDetail: string;
  defaultTaxInvoicePhone: string;
  defaultTaxInvoiceCustomerName: string;
  defaultTaxInvoiceCustomerTaxId: string;

  isQuantityDecimal: boolean = environment.transaction.isQuantityDecimal;
  isWhtTax: boolean = environment.transaction.isUseWht;
  isLoading: boolean;
  isLoadingCustomerList: boolean = false;
  isLoadingItemList: boolean = false;
  isLoadingShippingAddress: boolean = false;
  isLoadingBillingAddress: boolean = false;
  isEditShippingAddress: boolean = false;

  // isEditing: boolean;
  isVatExempted: boolean;
  public isSetValueWhenInit: boolean = false;
  isExcludeTax: boolean = false;
  isSameWHTAllRow: boolean;
  isDiscountByPercent: boolean;
  isProductReceive: boolean = false;
  isProductSent: boolean = false;
  // isEditForCreating: boolean;

  isJoinIV_TX_RE: boolean = false;

  documentStatus = DocumentStatus;
  stockType = StockType;
  kstockType = kStockType;

  uplineForm: FormGroup;

  whtList: any[] = [
    // { value: 0, label: 'ยังไม่ระบุ' },
    { value: 0, label: 'ไม่มี' },
    { value: 0.75, label: '0.75%' },
    { value: 1, label: '1%' },
    { value: 1.5, label: '1.5%' },
    { value: 2, label: '2%' },
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    // { value: 0, label: 'กำหนดเอง' },
  ];

  timelineData: TimelineItem[];

  shippingFee: number;
  shippingFeeDecimal: string;

  createDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: this.currentDate,
  };

  expiredDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(
      this.nextWeekDate.setDate(this.currentDate.getDate() + 7)
    ),
  };

  constructor(
    private _translateService: TranslateService,
    private _dragulaService: DragulaService,
    private _apiService: ApiService,
    private _documentService: DocumentService,
    private _documentApiService: DocumentApiService,
    private _modalService: NgbModal,
    private currencyPipe: CurrencyPipe,
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _componentsService: ComponentsService,
    private _contentTemplateService: QuatationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _calculateService: CalculateFuncService,
    private _globalFuncService: GlobalFuncService,
    private _changeDetectRef: ChangeDetectorRef,
    private _mainConfig: MainConfig
  ) {
    this.componentName = 'Item';
    this._unsubscribeAll = new Subject();
    this.maxLengthOfValue = this._mainConfig.maxLengthOfValue;
    this.componentName = this._contentTemplateService.componentName;
    this.timelineData = this._documentService.timelineData;
    this.invoiceApiPath = this._documentService.invoice.ApiPath;
    this.isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;

    this._route.paramMap
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((val) => {
        this.docID = this._route.snapshot.paramMap.get('id');
        this.refID = this._route.snapshot.paramMap.get('refID');
        this.copyObj = this._route.snapshot.paramMap.get('copyObj');

        if (this.copyObj) {
          this.isEditing = true;
          this.isEditForCreating = false;
        } else {
          if (this.isEditForCreating == true) {
          } else {
            this.isEditForCreating = this.refID ? true : false;
          }

          if (this.isEditing) {
            this.isEditing = true;
          } else {
            this.isEditing = this.docID ? true : false;
          }
        }
      });

    this.getShippingFee();

    this.quotationForm = this._formBuilder.group({
      [this.docCodeColumn]: [null],
      customerId: [null, [Validators.required]],
      issuerId: [null, []],
      monthId: [null, []],
      documentCreateDate: [null, []],
      documentExpireDate: [null, []],
      shippingAddress: [null, []],
      shippingAddressId: [null, []],
      shippingAddressDetail: [null, []],
      shippingPhone: [null, []],
      shippingCustomerName: [null, []],
      shippingCustomerTaxId: [null, []],
      taxInvoiceAddress: [null, []],
      taxInvoiceAddressId: [null, []],
      taxInvoiceAddressDetail: [null, []],
      taxInvoicePhone: [null, []],
      taxInvoiceCustomerName: [null, []],
      taxInvoiceCustomerTaxId: [null, []],
      isExcludeTax: [false, []],
      remarks: [null, []],
      preTaxDiscount: [0, []],
      discount: [0, []],
      shippingFee: [
        this.convertNumbertoMoneyFormat(this.shippingFee),
        [],
      ] /* set ค่าเริ่มต้นไว้่ */,
      isDiscountByPercent: [false, []],
      isVatExempt: [false, []],
      salesWHT: [0, []],
      taxRate: [this.taxRate, []],
      isProductReceived: [this.isProductReceive, []],
      isProductSent: [this.isProductSent, []],
      item: [null, []],
      refCode: [null, [Validators.required, Validators.pattern('[0-9]{10}$')]],
    });
  }

  checkUplineCode = false;
  uplineCode = true;
  isCheckCode = false;
  updateErr = false;

  UpdateRefCode() {
    this.isCheckCode = true;

    this._apiService
      .AddData(`customer/ReferralUpdate?id=${this.customerObj.id}`, {
        uplineCode: this.quotationForm.value.refCode,
      })
      .subscribe(
        (res) => {
          console.log(res);

          if (res.success) {
            this.checkUplineCode = true;
            this.isCheckCode = false;
            this.uplineCode = true;
            this.updateErr = false;
          } else {
            this.checkUplineCode = false;
            this.isCheckCode = false;
            this.uplineCode = false;
            this.updateErr = true;
          }
        },
        (err) => {
          this.checkUplineCode = false;
          this.isCheckCode = false;
          this.uplineCode = false;
          this.updateErr = true;
        }
      );
  }

  async getShippingFee() {
    try {
      // let res = await this._documentService.getDefaultArgument();
      let res = environment.transaction.shippingFee;
      if (res) {
        this.shippingFee = environment.transaction.shippingFee;

        let shippingFeeNumber = Number(this.shippingFee.toFixed(2));

        this.shippingFeeDecimal = shippingFeeNumber.toFixed(2);
      } else {
      }
    } catch (error) {}
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    if (this.isCreditNote) {
      moveItemInArray(
        this.quantityItemDefault,
        event.previousIndex,
        event.currentIndex
      );
      moveItemInArray(
        this.unitPriceItemDefault,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      moveItemInArray(
        this.quantityItemDefault,
        event.previousIndex,
        event.currentIndex
      );
      moveItemInArray(
        this.unitPriceItemDefault,
        event.previousIndex,
        event.currentIndex
      );
      moveItemInArray(
        this.discountItemDefault,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }
  SetLoadedState(): void {
    this.isLoading = false;
  }

  checkIsItemInArray(id: string) {
    return this.itemList.some((obj) => obj.id === id);
  }

  ngOnInit(): void {
    this.showSubmitBtn = true;
    this.isDiscountByPercent = false;

    this._authenticationService.currentUser
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => {
        this.currentUser = user;
      });

    this.initData();
  }

  ngAfterViewInit(): void {
    if (!this.isEditing && !this.isEditForCreating) {
      this.formatMoneyAllColumn('1');
      let docCode = document.getElementById('docCode') as HTMLFormElement;
      docCode.value = this.docCode;

      console.log(docCode.value);
      console.log(this.docCode);
    }

    this.formTable.changes
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.rows.forEach((element, index) => {
          if (element.rowType === 'item') {
            const rowID = index + 1;

            const amountForm = document.getElementById(
              'tbproductamount' + rowID
            ) as HTMLFormElement;
            const priceForm = document.getElementById(
              'tbproductprice' + rowID
            ) as HTMLFormElement;
            const discountForm = document.getElementById(
              'tbproductdiscount' + rowID
            ) as HTMLFormElement;

            const itemLabel = document
              .getElementById('tbproductcode' + rowID)
              .getElementsByClassName('ng-value-label');

            if (itemLabel.length >= 1) {
              let tempEl = itemLabel[0] as HTMLElement;
              let tempName = `${element.itemCode} - ${element.itemName}`;
              tempEl.innerText = this.checkIsItemInArray(element.itemId)
                ? `${tempName}`
                : `(${this._translateService.instant(
                    'General.Deprecated'
                  )}) ${tempName}`;
            }

            amountForm.value = this.convertNumbertoMoneyFormat(
              element.quantity,
              true,
              !this.isQuantityDecimal
            );
            priceForm.value = this.convertNumbertoMoneyFormat(
              element.unitPrice,
              true
            );
            discountForm.value = this.convertNumbertoMoneyFormat(
              element.discount,
              true
            );

            const tempWhtObj = this.whtList.find(
              (w) => w.value === element.whtRate
            );
            this.clearFormValidate(rowID.toString());
            this.onSelectedWHT(tempWhtObj, rowID.toString());
            this.CalculateProductRow(rowID);

            if (this.itemList.length >= 1) {
              let tempObj = this.itemList.find(
                (item) => item.id === element.itemId
              );

              if (tempObj) {
                element.inStockFGQuantity = tempObj.fgAvailableQuantity;
                element.inStockFOCQuantity = tempObj.focAvailableQuantity;
              }
              console.log(tempObj);
            }
          }
        });

        this.invalidDescriptionRows = [];
        if (this.rows[0].rowType === 'description') {
          this.descriptionInvalid = true;
          this.descriptionInvalidChange.emit(this.descriptionInvalid);
          this.invalidDescriptionRows.push(0);
          for (let index = 0; index < this.rows.length; index++) {
            const element = this.rows[index];
            if (element.rowType == 'description') {
              this.invalidDescriptionRows.push(index);
            }
            if (
              index + 1 < this.rows.length &&
              this.rows[index + 1].rowType == 'item'
            ) {
              break;
            }
          }
        } else {
          this.descriptionInvalid = false;
          this.descriptionInvalidChange.emit(this.descriptionInvalid);
        }

        if (!this.isSetValueWhenInit) {
          this.quotationForm.patchValue({
            salesWHT: this.quotationObj.salesWHT,
          });

          const sumWht = document.getElementById(
            'summaryWhtPrice'
          ) as HTMLFormElement;
          sumWht.value = this.convertNumbertoMoneyFormat(
            this.quotationObj.salesWHT
          );

          this.formatMoney('shippingFee', true);

          this.CalculateSummary(true);
          this.originalData = _.cloneDeep(this.rows);
          console.table(this.originalData);
          this.isSetValueWhenInit = true;
        }
      });

    this.getFormValue();
  }

  ngOnDestroy() {
    this.data = null;
    this.docTitle = null;
    // this.docCodeColumn = null;
    this.rows = [];
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    // clear boolean flag
    this.isEditing = false;
    this.isOnlyDecrease = false;
    this.hideSubmit = false;
    this.isCreditDebitMode = false;
    this.isCreditNote = false;
    this.isDebitNote = false;
    this.isCreateInvoice = false;
    this.isDateChange = true;
    this.isSubmit = false;
    this.showSubmitBtn = false;
    this.itemInvalid = [];
    this.sumTotalInvalid = false;
    this.isWhtTax = false;
    this.isLoading = false;
    this.isLoadingCustomerList = false;
    this.isLoadingShippingAddress = false;
    this.isLoadingBillingAddress = false;
    this.isLoadingItemList = false;
    this.isVatExempted = false;
    this.isSetValueWhenInit = false;
    this.isExcludeTax = false;
    this.isSameWHTAllRow = false;
    this.isDiscountByPercent = false;
    this.isEditForCreating = false;
    this.isJoinIV_TX_RE = false;
    this.workingData = [];
    this.originalData = [];
  }

  ngAfterViewChecked(): void {
    this.rows.forEach((element, index) => {
      let rowID = index + 1;
      const itemLabel = document
        .getElementById('tbproductcode' + rowID)
        .getElementsByClassName('ng-value-label');

      if (itemLabel.length >= 1) {
        let tempEl = itemLabel[0] as HTMLElement;
        let tempName = `${element.itemCode} - ${element.itemName}`;
        tempEl.innerText = this.checkIsItemInArray(element.itemId)
          ? `${tempName}`
          : `(Deprecated) ${tempName}`;
      }
    });

    this._changeDetectRef.detectChanges();
  }

  addItemRow(obj?: QuotationItem): void {
    var self = this;
    let tempRow: TempRowQuotation = new TempRowQuotation();

    if (obj) {
      tempRow.rowType = 'item';
      tempRow.itemId = obj.itemId;
      tempRow.itemName = obj.itemName;
      tempRow.itemCode = obj.itemCode;
      tempRow.stockType = obj.stockType;
      tempRow.description = obj.description[0].description;
      tempRow.discount = obj.isDiscountByPercent
        ? obj.percentDiscount
        : obj.discount;
      tempRow.preTaxDiscount = obj.isDiscountByPercent
        ? obj.percentDiscount
        : obj.preTaxDiscount;
      tempRow.quantity = obj.quantity;
      tempRow.unit = obj.unit;
      tempRow.unitPrice = obj.unitPrice;
      tempRow.uniqueId = obj.uniqueId;
      tempRow.whtRate = obj.whtRate;
      tempRow.salesWHT = obj.salesWHT;
      tempRow.grandTotal = obj.grandTotal;
      tempRow.isDiscountByPercent = obj.isDiscountByPercent;

      this.rows.push(tempRow);

      if (obj.description.length > 1) {
        obj.description.forEach((element, index) => {
          if (element != obj.description[0]) {
            this.addDescriptionRow(false, element.description);
          }
        });
      }

      this.updateQuantityItemDefault();
    } else {
      this.rows.push({
        rowType: 'item',
        itemId: null,
        itemName: null,
        stockType: this.stockType.FG,
        quantity: 1.0,
        unit: null,
        unitPrice: 0.0,
        preTaxDiscount: 0.0,
        discount: 0.0,
        isDiscountByPercent: false,
        remarks: '',
        description: '',
        uniqueId: '',
        whtRate: 0.0,
        salesWHT: 0.0,
        grandTotal: 0.0,
        preTaxAmount: 0.0,
        isNewRow: true,
      });
    }

    this.CalculateSummary();
  }

  updateQuantityItemDefault(): void {
    this.quantityItemDefault = this.rows
      .filter((row) => row.rowType === 'item')
      .map((item) => item.quantity);
  }

  onTaxTypeChange(event: any) {
    this.selectedTaxType = event.target.value === 'true'; // Convert string to boolean
  }

  addDescriptionRow(isNew: boolean, desc?: string): void {
    const newRow: TempRowQuotation = {
      rowType: 'description',
      itemId: null,
      quantity: null,
      unit: null,
      unitPrice: null,
      preTaxDiscount: 0.0,
      discount: 0.0,
      isDiscountByPercent: false,
      remarks: '',
      uniqueId: '',
      description: desc ? desc : '',
      whtRate: 0.0,
      salesWHT: 0.0,
      grandTotal: 0.0,
      preTaxAmount: 0.0,
      isNewRow: isNew,
    };

    this.rows.push(newRow);
  }

  getGrandTotal() {
    let sumWhtPrice = document.getElementById(
      'summaryTaxExclusive'
    ) as HTMLFormElement;

    let grandTotalPrice = document.getElementById(
      'grandTotalPrice'
    ) as HTMLElement;
    let discountAllBillPrice = document.getElementById(
      'summaryTaxPrice'
    ) as HTMLFormElement;
    3;
    let sumWhtTax = document.getElementById(
      'summaryWhtPrice'
    ) as HTMLInputElement;

    let summaryPrice = document.getElementById(
      'summaryPrice'
    ) as HTMLInputElement;
    const dataObject = {
      sumWhtPrice: sumWhtPrice.textContent,
      grandTotalPrice: grandTotalPrice.textContent,
      discountAllBillPrice: discountAllBillPrice.textContent,
      sumWhtTax: isNaN(parseFloat(sumWhtTax.value)) ? '0.00' : sumWhtTax.value,
      summaryPrice: summaryPrice.textContent,
    };

    return dataObject;
  }

  removeRow(index: number): void {
    if (!this.isCreditDebitMode && !this.isCreateInvoice && !this.isCreateGR) {
      this.rows.splice(index, 1);
      this.itemInvalid.splice(index, 1);
      this.quantityInvalid.splice(index, 1);
      this.unitPriceInvalid.splice(index, 1);
      this.discountInvalid.splice(index, 1);
      this.quantityItemDefault.splice(index, 1);
      this.unitPriceItemDefault.splice(index, 1);
      this.discountItemDefault.splice(index, 1);
      this.numQuantityArray.splice(index, 1);
      this.numUnitPriceArray.splice(index, 1);
      this.numDiscountArray.splice(index, 1);
      this.tempNetAmountArray.splice(index, 1);

      this.CalculateSummary();
      this.validateProductRow(index);
      this.validateSumGrandTotal();
    } else if (this.isDebitNote) {
      this.rows.splice(index, 1);
      this.itemInvalid.splice(index, 1);
      this.quantityInvalid.splice(index, 1);
      this.unitPriceInvalid.splice(index, 1);
      this.discountInvalid.splice(index, 1);
      this.quantityItemDefault.splice(index, 1);
      this.unitPriceItemDefault.splice(index, 1);
      this.discountItemDefault.splice(index, 1);
      this.numQuantityArray.splice(index, 1);
      this.numUnitPriceArray.splice(index, 1);
      this.numDiscountArray.splice(index, 1);
      this.tempNetAmountArray.splice(index, 1);

      this.CalculateSummary();
      this.validateProductRow(index);
      this.validateSumGrandTotal();
    } else {
      this.rows.splice(index, 1);
      if (this.isCreditNote || this.isCreateInvoice || this.isCreateGR) {
        this.itemInvalid.splice(index, 1);
        this.quantityInvalid.splice(index, 1);
        this.quantityWarning.splice(index, 1);
        this.unitPriceInvalid.splice(index, 1);
        this.discountInvalid.splice(index, 1);
        this.quantityItemDefault.splice(index, 1);
        this.unitPriceItemDefault.splice(index, 1);
        this.numQuantityArray.splice(index, 1);
        this.numUnitPriceArray.splice(index, 1);
      }
      this.CalculateSummary();
      this.validateProductRow(index);
      this.validateSumGrandTotal();
    }
  }

  calculateRowData(row: TempRowQuotation): void {
    const rowIndex = this.workingData.indexOf(row);
    this.quantityItemDefault[rowIndex] = row.quantity;
    this.unitPriceItemDefault[rowIndex] = row.unitPrice;
    if (this.isDebitNote || this.isCreateInvoice || this.isCreateGR) {
      this.discountItemDefault[rowIndex] = row.discount;
    }
  }

  calculateAllRowData(): void {
    this.workingData.forEach((row) => {
      this.calculateRowData(row);
    });
  }

  resetRow(): void {
    this.originalData = this.originalData.filter((item) => !item.isNewRow);

    // Revert workingData to originalData
    this.workingData = _.cloneDeep(this.originalData);

    // Reset item validity and related values
    this.itemInvalid = new Array(this.workingData.length).fill(false);
    this.descriptionInvalid = false;
    this.totalDiscountInvalid = false;
    this.sumTotalInvalid = false;
    this.quantityInvalid = new Array(this.workingData.length).fill(false);
    this.quantityWarning = new Array(this.workingData.length).fill(false);
    this.unitPriceInvalid = new Array(this.workingData.length).fill(false);
    this.discountInvalid = new Array(this.workingData.length).fill(false);

    this.isDiscountByPercent = this.discountTypeDefault;
    const totalDiscountInput = document.getElementById(
      'discountAllBill'
    ) as HTMLInputElement;
    totalDiscountInput.value = this.totalDiscountDefault.toString();

    // const shippingFeeInput = document.getElementById(
    //   'shippingFee'
    // ) as HTMLInputElement;
    // shippingFeeInput.value = this.shippingFee.toString();

    // Recalculate summary and validate sum grand total
    this.calculateAllRowData();
    this.CalculateSummary();
    this.validateSumGrandTotal();

    // Update rows and emit changes
    this.rows = _.cloneDeep(this.workingData);
    this.itemInvalidChange.emit(this.itemInvalid);
    this.totalDiscountInvalidChange.emit(this.totalDiscountInvalid);
    this.sumTotalInvalidChange.emit(this.sumTotalInvalid);

    console.table(this.rows);
  }

  getRowIndex(row: any): number {
    let index = 1;
    let foundItem = false;

    for (const r of this.rows) {
      if (r.rowType === 'item') {
        if (r === row) {
          foundItem = true;
          break;
        }
        index++;
      }
    }

    return foundItem ? index : null;
  }

  clearFormValidate(rowID: string): void {
    let descForm = document.getElementById(
      'tbproductdescription' + rowID
    ) as HTMLElement;
    descForm.classList.remove('table-field-invalid');

    let itemForm = document.getElementById(
      'tbproductcode' + rowID
    ) as HTMLElement;
    itemForm.classList.remove('table-field-invalid');

    let discountBorderForm = document.getElementById(
      'tbproductdiscountborder' + rowID
    ) as HTMLElement;
    discountBorderForm.classList.remove('table-field-invalid');
  }

  initData() {
    this.initializeForm();

    if (this.hideSubmit) {
      this.showSubmitBtn = false;
    }

    this.quotationObj = new Quotation();
    this.quotationObj.transactionStatusId = 0;
    this.quotationObj.salesWHT = 0.0;

    if (this.isEditing || this.isEditForCreating) {
      this.getQuotationData();
      if (this.isEditForCreating) {
        // this.getLastCode();
      }
    } else {
      if (this.isQuotation) {
        this.getLastCode('Quotation');
      } else if (this.isPurchaseRequest) {
        this.getLastCode('PurchaseRequisition');
      }
      this.customerObj = new Customer();
      this.addItemRow();
      this.SetLoadedState();
    }
  }

  initDataForce() {
    this.quotationForm = null; // Set quotationForm to null

    this.initializeForm();

    this.customerObj = new Customer();
    this.rows = [];
    this.isSetValueWhenInit = false;
    this.SetLoadedState();
  }

  initializeForm() {
    this.SetLoadingState();
    this.getCustomerList();
    this.getitemList();

    this.quotationForm = this._formBuilder.group({
      [this.docCodeColumn]: [null],
      customerId: [null, [Validators.required]],
      issuerId: [null, []],
      monthId: [null, []],
      documentCreateDate: [null, []],
      documentExpireDate: [null, []],
      shippingAddress: [null, []],
      shippingAddressId: [null, []],
      shippingAddressDetail: [null, []],
      shippingPhone: [null, []],
      shippingCustomerName: [null, []],
      shippingCustomerTaxId: [null, []],
      taxInvoiceAddress: [null, []],
      taxInvoiceAddressId: [null, []],
      taxInvoiceAddressDetail: [null, []],
      taxInvoicePhone: [null, []],
      taxInvoiceCustomerName: [null, []],
      taxInvoiceCustomerTaxId: [null, []],
      isExcludeTax: [false, []],
      remarks: [null, []],
      preTaxDiscount: [0, []],
      discount: [0, []],
      shippingFee: [
        this.convertNumbertoMoneyFormat(this.shippingFee),
        [],
      ] /* set ค่าเริ่มต้นไว้่ */,
      isDiscountByPercent: [false, []],
      isVatExempt: [false, []],
      salesWHT: [0, []],
      taxRate: [this.taxRate, []],
      isProductReceived: [this.isProductReceive, []],
      isProductSent: [this.isProductSent, []],
      item: [null, []],
      refCode: [
        { value: null, disabled: true },
        [Validators.required, Validators.pattern('[0-9]{10}$')],
      ],
    });
  }

  getLastCode(apiPath: string): Promise<void> {
    console.log('getting last code');

    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService.GetDocRunningNumber(apiPath).subscribe((res) => {
        let docCode = document.getElementById('docCode') as HTMLFormElement;
        self.docCode = res.data.resultData[0];

        docCode.value = self.docCode;

        self.quotationObj[this.docCodeColumn] = res.data.resultData[0];
        console.log('loaded last code');

        resolve();
      });
    });
  }

  validator() {
    let discount = this.quotationForm.value.preTaxDiscount;
    if (this.amount < 0) {
      return true;
    }
  }

  getQuotationData() {
    var self = this;
    self.quotationObj = self.data;
  }

  get f() {
    return this.quotationForm.controls;
  }

  getCustomerList(): Promise<any> {
    this.isLoadingCustomerList = true;
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customer', {
          isDelete: false,
          isActive: true,
          SortPath: 'detail',
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          self.customerList = res.data.resultData;
          if (this.customerList) {
            this.isLoadingCustomerList = false;
          }
        });
    });
  }

  getCustomerAddress(customerId: string): Promise<any> {
    this.isLoadingBillingAddress = true;
    var self = this;
    return new Promise<void>((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          self.customerAddressList = res.data.resultData.filter(
            (obj) => obj.useForShipping == false && obj.useForBilling == true
          );
          self.shippingAddressList = res.data.resultData.filter(
            (obj) => obj.useForShipping == true && obj.useForBilling == false
          );

          self.customerAddressList.sort((a, b) => (b.isDefault ? 1 : -1));
          self.shippingAddressList.sort((a, b) => (b.isDefault ? 1 : -1));

          this.isLoadingBillingAddress = false;
          this.isLoadingShippingAddress = false;

          if (
            (this.isQuotation || this.isPurchaseRequest) &&
            this.isEditing &&
            !this.isInitAddress
          ) {
            const defaultCustomerAddress = this.customerAddressList.find(
              (address) =>
                address.id == this.quotationForm.value.taxInvoiceAddressId
            );
            const defaultShippingAddress = this.shippingAddressList.find(
              (address) =>
                address.id == this.quotationForm.value.shippingAddressId
            );

            this.quotationForm
              .get('taxInvoiceAddress')
              .setValue(defaultCustomerAddress);

            this.quotationForm
              .get('shippingAddress')
              .setValue(defaultShippingAddress);

            this.onSelectedBillingAddress();
            this.onSelectedShippingAddress();
          }

          if (
            !this.isCreditDebitMode &&
            !this.isCreateInvoice &&
            !this.isCreateGR &&
            !(
              (this.isQuotation || this.isPurchaseRequest) &&
              this.isEditing &&
              !this.isInitAddress
            )
          ) {
            const defaultCustomerAddress = this.customerAddressList.find(
              (address) => address.isDefault
            );
            const defaultShippingAddress = this.shippingAddressList.find(
              (address) => address.isDefault
            );

            this.quotationForm
              .get('taxInvoiceAddress')
              .setValue(defaultCustomerAddress);

            this.quotationForm
              .get('shippingAddress')
              .setValue(defaultShippingAddress);

            this.onSelectedBillingAddress();
            this.onSelectedShippingAddress();
          }
          resolve();
        });
    });
  }

  isInitAddress: boolean = false;

  getitemList(): Promise<any> {
    this.isLoadingItemList = true;
    let params: any = {};
    params.isDelete = false;
    params.verbose = false;
    params.includeMediaURL = false;

    if (this.isQuotation || this.isPurchaseRequest) {
      params.isActive = true;
    }

    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('item', params)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          self.itemList = res.data.resultData;
          if (self.itemList) {
            this.isLoadingItemList = false;
          }
          this.quantityStockDefault = self.itemList.map(
            (item: { quantity: number }) => item.quantity
          );

          if (this.isEditing && (this.isQuotation || this.isPurchaseRequest)) {
            this.patchValueStock();
          }
        });
    });
  }

  patchValueStock() {
    this.rows.forEach((element, index) => {
      let tempItem = this.itemList.find((el) => el.id === element.itemId);
      element.inStockFGQuantity = tempItem.fgAvailableQuantity;
      element.inStockFOCQuantity = tempItem.focAvailableQuantity;
    });
  }

  getCustomerData(id) {
    this._apiService
      .GetDataById('Customer', id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.customerObj = res.data.resultData[0];
          // let saleRef = document.getElementById('saleRef') as HTMLFormElement;
          // if (saleRef) {
          //   saleRef.value = this.customerObj.uplineCode
          //     ? this.customerObj.uplineCode
          //     : '-';
          // }
          let useCode = document.getElementById('useCode') as HTMLFormElement;

          if (useCode) {
            useCode.checked = false;
          }

          if (!this.customerObj.uplineCode) {
            this.uplineCode = false;
            this.quotationForm.patchValue({
              refCode: '-',
            });

            this.f.refCode.enable();
            this.checkUplineCode = false;
          } else {
            this.uplineCode = true;
            this.quotationForm.patchValue({
              refCode: this.customerObj.uplineCode,
            });

            this.f.refCode.disable();
            this.checkUplineCode = true;
          }
          this.getCustomerAddress(this.customerObj.id);
        },
        (err) => {}
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges');
    this.idDocument = this.data.id;

    if (this.isEditing || this.isEditForCreating) {
      if (this.data.item) {
        this.getCustomerData(this.data.customerId);
        this.setFormValue(this.data);

        if (this.isOnlyDecrease) {
          this.quotationForm.controls.isExcludeTax.disable();
          this.quotationForm.controls.customerId.disable();
          this.quotationForm.controls.taxRate.disable();
        }
        if (this.isDebitNote) {
          this.quotationForm.controls.isExcludeTax.disable();
          this.quotationForm.controls.customerId.disable();
        }
        if (this.isCreditNote) {
          this.quotationForm.controls.discount.disable();
          this.quotationForm.controls.isDiscountByPercent.disable();
        }
      }
    } else {
      // this.setFormValue(this.quotationObj);
      let docCodeInput = document.getElementById('docCode') as HTMLFormElement;
      if (this.docCode && docCodeInput) {
        docCodeInput.value = this.docCode;
        this.quotationObj[this.docCodeColumn] = this.docCode;
      }
    }
  }

  setFormValue(obj: any): void {
    console.log(obj);

    var self = this;
    this.documentRef = obj.references;
    this.isExcludeTax = obj ? obj.isExcludeTax : false;
    let docCode = document.getElementById('docCode') as HTMLFormElement;
    let docRef = document.getElementById('docRef') as HTMLFormElement;

    if (this.isEditing || this.isEditForCreating) {
      if (this.isEditForCreating) {
        if (this.isDebitNote || this.isCreditNote) {
          this.onSelectedCustomer(obj.customerId);
          if (obj['invoiceCode'] && docCode) {
            docCode.value = obj['invoiceCode'];
            this.docCode = obj['invoiceCode'];
          }
        } else {
          docRef.value = obj['saleOrderCode'];
          this.onSelectedCustomer(obj.customerId);
          if (this.docCodeColumn && docCode) {
            docCode.value = obj[this.docCodeColumn];
            this.docCode = obj[this.docCodeColumn];
          }
        }
      } else {
        this.onSelectedCustomer(obj.customerId);
        if (this.docCodeColumn && docCode) {
          docCode.value = obj[this.docCodeColumn];
          this.docCode = obj[this.docCodeColumn];
        }
      }

      let dateFormValue = obj.documentCreateDate;
      let dateToValue = obj.documentExpireDate;
      let tempDateForm = new Date(dateFormValue);
      let tempDateTo = new Date(dateToValue);
      this.taxRate = obj.taxRate;
      this.isDiscountByPercent = obj.isDiscountByPercent;
      this.oldGrandTotal = obj.grandTotal;

      this.createDateOptions = {
        dateFormat: 'd/m/Y',
        enableTime: false,
        defaultDate: tempDateForm,
      };

      this.expiredDateOptions = {
        dateFormat: 'd/m/Y',
        enableTime: false,
        defaultDate: tempDateTo,
      };

      this.taxInvoiceAddressId = obj.taxInvoiceAddressId;
      this.taxInvoiceAddressDetail = obj.taxInvoiceAddressDetail;
      this.taxInvoicePhone = obj.taxInvoicePhone;
      this.taxInvoiceCustomerName = obj.taxInvoiceCustomerName;
      this.taxInvoiceCustomerTaxId = obj.taxInvoiceCustomerTaxId;

      this.shippingAddressId = obj.shippingAddressId;
      this.shippingAddressDetail = obj.shippingAddressDetail;
      this.shippingPhone = obj.shippingPhone;
      this.shippingCustomerName = obj.shippingCustomerName;
      this.shippingCustomerTaxId = obj.shippingCustomerTaxId;

      this.defaultShippingAddressId = _.cloneDeep(this.shippingAddressId);
      this.defaultShippingAddressDetail = _.cloneDeep(
        this.shippingAddressDetail
      );
      this.defaultShippingPhone = _.cloneDeep(this.shippingPhone);
      this.defaultShippingCustomerName = _.cloneDeep(this.shippingCustomerName);
      this.defaultShippingCustomerTaxId = _.cloneDeep(
        this.shippingCustomerTaxId
      );

      this.quotationForm.patchValue({
        [this.docCodeColumn]: obj[this.docCodeColumn],
        documentCreateDate: tempDateForm.toISOString(),
        documentExpireDate: tempDateTo.toISOString(),
        customerId: obj.customerId,
        remarks: obj.remarks,
        taxRate: this.taxRate,
        isProductReceived: this.isProductReceive,
        isProductSent: this.isProductSent,
        salesWHT: obj.salesWHT,
        discount: obj.isDiscountByPercent ? obj.percentDiscount : obj.discount,
        shippingAddressId: obj.shippingAddressId,
        shippingAddressDetail: obj.shippingAddressDetail,
        shippingPhone: obj.shippingPhone,
        shippingCustomerName: obj.shippingCustomerName,
        shippingCustomerTaxId: obj.shippingCustomerTaxId,
        taxInvoiceAddressId: obj.taxInvoiceAddressId,
        taxInvoiceAddressDetail: obj.taxInvoiceAddressDetail,
        taxInvoicePhone: obj.taxInvoicePhone,
        taxInvoiceCustomerName: obj.taxInvoiceCustomerName,
        taxInvoiceCustomerTaxId: obj.taxInvoiceCustomerTaxId,
        shippingFee: this.convertNumbertoMoneyFormat(
          this.shippingFee
        ) /* hard code ไว้ ถ้าจะดึงจากเอกสารให้ใช้ obj.shippingFee */,
        preTaxDiscount: obj.isDiscountByPercent
          ? obj.percentDiscount
          : obj.preTaxDiscount,
        isDiscountByPercent: obj.isDiscountByPercent,
      });

      console.log('quotationForm.value : ', this.quotationForm.value);

      if (obj.isVatExempt) {
        this.taxRate = 0;
        this.isVatExempted = true;
        this.quotationForm.patchValue({ taxRate: -1 });
      }

      obj.item.forEach((element, index) => {
        this.addItemRow(element);
      });
    } else {
    }

    let discountAllBillPrice = document.getElementById(
      'discountAllBill'
    ) as HTMLFormElement;

    if (discountAllBillPrice) {
      let discount = discountAllBillPrice.value
        ? parseFloat(discountAllBillPrice.value.replace(/,/g, ''))
        : 0.0;

      discountAllBillPrice.value = this.convertNumbertoMoneyFormat(discount);
    }

    if (this.isCreditDebitMode || this.isCreateInvoice || this.isCreateGR) {
      let refOldPrice = document.getElementById('refOldPrice') as HTMLElement;
      let oldObj = this._documentApiService
        .GetDocData(this.invoiceApiPath, obj.id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.CalculateGrandTotal();
        });

      this.getPreTaxAmount = obj.item.map((item) => item.preTaxAmount);

      this.itemDefault = this.rows;

      this.totalDiscountDefault = obj.preTaxDiscount;
      this.shippingFeeDefault = obj.shippingFee;
      this.discountTypeDefault = obj.isDiscountByPercent;

      this.extractItemDefault();
    }
    this.CalculateSummary();

    this.SetLoadedState();

    // this.CalculateWhtByRow;
  }

  showRemoveButton(index: number) {
    if (index >= 0 && index < this.rows.length) {
      const row = this.rows[index];
      // return row.isNewRow;
      return true;
    }
    return false;
  }

  extractItemDefault() {
    this.quantityItemDefault = this.itemDefault.map(
      (item: { quantity: any }) => item.quantity
    );

    this.unitPriceItemDefault = this.itemDefault.map(
      (item: { unitPrice: any }) => item.unitPrice
    );

    this.discountItemDefault = this.itemDefault.map(
      (item: { discount: any }) => item.discount
    );

    this.grandTotalItemDefault = this.itemDefault.map(
      (item: { grandTotal: any }) => item.grandTotal
    );

    // this.getPreTaxAmount = this.itemDefault.map(
    //   (item: { preTaxAmount: any }) => item.preTaxAmount
    // );
  }

  formatMoneyAllColumn(rowId: string) {
    this.formatMoney(
      'tbproductamount' + rowId.toString(),
      true,
      true,
      parseInt(rowId),
      'quantity',
      this.isQuantityDecimal
    );
    this.formatMoney('tbproductprice' + rowId.toString(), true);
    this.formatMoney('tbproductdiscount' + rowId.toString(), true);
  }

  formatMoney(
    elementID: string,
    showFourDigit: boolean = false,
    isElementInTable: boolean = false,
    rowIndex?: number,
    tableColumn?: string,
    notUseDecimal?: boolean
  ) {
    let element = document.getElementById(elementID) as HTMLFormElement;
    let formattedAmount = element.value;

    const moneyPattern = /^-?(\d{1,3}(,\d{3})*|\d+)(\.\d+)?$/;
    let isMoney = moneyPattern.test(formattedAmount);

    if (element.value) {
      if (!isMoney) {
        formattedAmount = this.convertNumbertoMoneyFormat(0);
        element.value = formattedAmount;
        return;
      }
      formattedAmount = formattedAmount.replace(/,/g, '');

      if (notUseDecimal === false) {
        formattedAmount = this._globalFuncService.FormatToMoney(
          parseFloat(formattedAmount),
          showFourDigit,
          !this.isQuantityDecimal
        );
      } else {
        formattedAmount = this._globalFuncService.FormatToMoney(
          parseFloat(formattedAmount),
          showFourDigit
        );
      }

      // Handle the replacement based on notUseDecimal
      if (notUseDecimal === false) {
        element.value = formattedAmount;
      } else {
        element.value = formattedAmount.replace('$', '');
      }
    } else {
      if (isElementInTable && rowIndex !== undefined && tableColumn) {
        this.rows[rowIndex - 1][tableColumn] =
          notUseDecimal === false ? 0 : 0.0;
      }

      // Handle the value assignment based on notUseDecimal
      if (notUseDecimal === false) {
        element.value = '0';
      } else {
        element.value = '0.00';
      }
    }
  }

  removeComma(element) {
    let formattedAmount: string;
    formattedAmount = element.target.value;
    element.target.value = formattedAmount.replace(/,/g, '');
  }

  validateDotDecimal(rowID: number, event: KeyboardEvent): void {
    // Prevent input of decimal point key (190) and numpad decimal key (110)
    if (event.key === '.' || event.keyCode === 190 || event.keyCode === 110) {
      event.preventDefault();
    }
  }

  convertNumbertoMoneyFormat(
    value: number,
    showFourDigit: boolean = false,
    disableDecimals: boolean = false
  ): string {
    return this._globalFuncService.FormatToMoney(
      value,
      showFourDigit,
      disableDecimals
    );
  }

  increaseAmount(rowID) {
    let quantity = document.getElementById(
      'tbproductamount' + rowID
    ) as HTMLFormElement;

    let numQuantity = parseFloat(quantity.value.replace(/,/g, ''));

    if (this.isCreditNote || this.isCreateInvoice) {
      const defaultQuantity = this.quantityItemDefault[rowID - 1];
      const newQuantity = Math.min(numQuantity + 1, defaultQuantity);
      this.rows[rowID - 1].quantity = newQuantity;
      quantity.value = newQuantity.toFixed(2);

      this.formatMoney(
        'tbproductamount' + rowID.toString(),
        true,
        true,
        parseInt(rowID),
        'quantity',
        this.isQuantityDecimal
      );
    } else {
      if (this.isQuantityDecimal) {
        this.rows[rowID - 1].quantity = numQuantity + 1;

        let tempNumQuantity = this._globalFuncService.FormatToMoney(
          numQuantity + 1,
          true,
          !this.isQuantityDecimal
        );
        quantity.value = tempNumQuantity
          ? tempNumQuantity
          : this.isQuantityDecimal
          ? '0'
          : '0.00';

        this.formatMoney(
          'tbproductamount' + rowID.toString(),
          true,
          true,
          parseInt(rowID),
          'quantity',
          this.isQuantityDecimal
        );
      } else {
        // If isQuantityDecimal is false, add 1 without decimals

        this.rows[rowID - 1].quantity = Math.floor(numQuantity) + 1;
        quantity.value = this.rows[rowID - 1].quantity.toString();
        this.formatMoney(
          'tbproductamount' + rowID.toString(),
          true,
          true,
          parseInt(rowID),
          'quantity',
          this.isQuantityDecimal
        );
      }
    }

    this.CalculateProductRow(rowID);
    this.validateProductRow(rowID);
  }

  decreaseAmount(rowID) {
    let quantity = document.getElementById(
      'tbproductamount' + rowID
    ) as HTMLFormElement;
    let numQuantity = parseFloat(quantity.value.replace(/,/g, ''));

    if (this.isCreditNote || this.isCreateInvoice) {
      const newQuantity = Math.max(numQuantity - 1, 1);
      this.rows[rowID - 1].quantity = this.isQuantityDecimal
        ? numQuantity
        : Math.floor(numQuantity);

      let tempNumQuantity = this._globalFuncService.FormatToMoney(
        newQuantity,
        true,
        !this.isQuantityDecimal
      );
      quantity.value = tempNumQuantity
        ? tempNumQuantity
        : this.isQuantityDecimal
        ? '0'
        : '0.00';

      this.formatMoney(
        'tbproductamount' + rowID.toString(),
        true,
        true,
        parseInt(rowID),
        'quantity',
        this.isQuantityDecimal
      );
    } else {
      if (this.isQuantityDecimal) {
        const newQuantity = Math.max(numQuantity - 1, 1);
        this.rows[rowID - 1].quantity = newQuantity;

        let tempNumQuantity = this._globalFuncService.FormatToMoney(
          newQuantity,
          true,
          !this.isQuantityDecimal
        );
        quantity.value = tempNumQuantity
          ? tempNumQuantity
          : this.isQuantityDecimal
          ? '0'
          : '0.00';

        this.formatMoney(
          'tbproductamount' + rowID.toString(),
          true,
          true,
          parseInt(rowID),
          'quantity',
          this.isQuantityDecimal
        );
      } else {
        const newQuantity = Math.max(Math.floor(numQuantity) - 1, 1);
        this.rows[rowID - 1].quantity = newQuantity;

        quantity.value = newQuantity.toString();
      }
    }

    this.CalculateProductRow(rowID);
    this.validateProductRow(rowID);
  }

  onDateFromChange(event) {
    let dateform = this.quotationForm.controls.documentCreateDate.value;
    this.expriedDate.flatpickr.set({
      minDate: new Date(dateform),
    });
  }

  onSelectedCustomer(customerId) {
    this.customerObj = this.customerList.find((it) => {
      return it['id'] == customerId;
    });
    // let saleRef = document.getElementById('saleRef') as HTMLFormElement;
    // if (saleRef && this.customerObj) {
    //   saleRef.value = this.customerObj.uplineCode
    //     ? this.customerObj.uplineCode
    //     : '-';
    // }

    let useCode = document.getElementById('useCode') as HTMLFormElement;

    if (useCode) {
      useCode.checked = false;
    }

    if (!this.customerObj.uplineCode) {
      this.uplineCode = false;
      this.quotationForm.patchValue({
        refCode: '-',
      });

      this.f.refCode.enable();
      this.checkUplineCode = false;
    } else {
      this.uplineCode = true;
      this.quotationForm.patchValue({
        refCode: this.customerObj.uplineCode,
      });

      this.f.refCode.disable();
      this.checkUplineCode = true;
    }
    this.getCustomerAddress(customerId).then(() => {
      this.isInitAddress = true;
    });
  }

  onUseCode(e: any) {
    this.updateErr = false;

    if (e.target.checked) {
      this.quotationForm.patchValue({
        refCode: '0000000001',
      });
      this.f.refCode.disable();
    } else {
      this.quotationForm.patchValue({
        refCode: '-',
      });
      this.f.refCode.enable();
    }
  }

  onSelectedBillingAddress() {
    let address = this.quotationForm.get('taxInvoiceAddress').value;

    this.taxInvoiceAddressId = address.id;
    this.taxInvoiceAddressDetail = this.GetAddressTemplate(address);
    this.taxInvoicePhone = address.phone;
    this.taxInvoiceCustomerName = address.detail;
    this.taxInvoiceCustomerTaxId = address.taxId;

    this.quotationForm.patchValue({
      taxInvoiceAddressId: this.taxInvoiceAddressId,
      taxInvoiceAddressDetail: this.taxInvoiceAddressDetail,
      taxInvoicePhone: this.taxInvoicePhone,
      taxInvoiceCustomerName: this.taxInvoiceCustomerName,
      taxInvoiceCustomerTaxId: this.taxInvoiceCustomerTaxId,
    });
  }

  onSelectedShippingAddress() {
    let address = this.quotationForm.get('shippingAddress').value;

    this.shippingAddressId = address.id;
    this.shippingAddressDetail = this.GetAddressTemplate(address);
    this.shippingPhone = address.phone;
    this.shippingCustomerName = address.detail;
    this.shippingCustomerTaxId = address.taxId;

    this.quotationForm.patchValue({
      shippingAddressId: this.shippingAddressId,
      shippingAddressDetail: this.shippingAddressDetail,
      shippingPhone: this.shippingPhone,
      shippingCustomerName: this.shippingCustomerName,
      shippingCustomerTaxId: this.shippingCustomerTaxId,
    });
  }

  editShippingAddress() {
    this.isEditShippingAddress = !this.isEditShippingAddress;

    this.shippingAddressId = this.defaultShippingAddressId;
    this.shippingAddressDetail = this.defaultShippingAddressDetail;
    this.shippingPhone = this.defaultShippingPhone;
    this.shippingCustomerName = this.defaultShippingCustomerName;
    this.shippingCustomerTaxId = this.defaultShippingCustomerTaxId;

    this.quotationForm.patchValue({
      shippingAddressId: this.shippingAddressId,
      shippingAddressDetail: this.shippingAddressDetail,
      shippingPhone: this.shippingPhone,
      shippingCustomerName: this.shippingCustomerName,
      shippingCustomerTaxId: this.shippingCustomerTaxId,
    });

    if (this.isEditShippingAddress) {
      let defaultShippingAddress = this.shippingAddressList.find(
        (address) => address.id == this.shippingAddressId
      );

      if (defaultShippingAddress) {
        this.quotationForm
          .get('shippingAddress')
          .setValue(defaultShippingAddress);
      }
    }
  }

  cancelEditShippingAddress() {
    this.isEditShippingAddress = !this.isEditShippingAddress;

    this.shippingAddressId = this.defaultShippingAddressId;
    this.shippingAddressDetail = this.defaultShippingAddressDetail;
    this.shippingPhone = this.defaultShippingPhone;
    this.shippingCustomerName = this.defaultShippingCustomerName;
    this.shippingCustomerTaxId = this.defaultShippingCustomerTaxId;

    this.quotationForm.patchValue({
      shippingAddressId: this.shippingAddressId,
      shippingAddressDetail: this.shippingAddressDetail,
      shippingPhone: this.shippingPhone,
      shippingCustomerName: this.shippingCustomerName,
      shippingCustomerTaxId: this.shippingCustomerTaxId,
    });
  }

  onSelectedTaxMode(event) {
    let status = event.target.value == 'true' ? true : false;
    this.isExcludeTax = status;

    this.rows.forEach((element, index) => {
      this.formatMoneyAllColumn((index + 1).toString());
      this.CalculateProductRow(index + 1);
    });
  }

  onSelectProductReceive(selectedValue: boolean) {
    this.isProductReceive = selectedValue;
    this.quotationForm
      .get('isProductReceived')
      .patchValue(this.isProductReceive);
  }

  onSelectProductSent(selectedValue: boolean) {
    this.isProductSent = selectedValue;
    this.quotationForm.get('isProductSent').patchValue(this.isProductSent);
  }

  onChangeDiscountMode(
    rowIndex: number,
    value: boolean,
    discountAllBill?: boolean
  ) {
    if (!discountAllBill) {
      this.rows[rowIndex - 1].isDiscountByPercent = value;
    } else {
      this.isDiscountByPercent = value;
    }

    // this.CalculateProductRow(rowIndex);
    this.CalculateSummary();
    this.CalculateGrandTotal();
    this.validateSumGrandTotal();
  }

  showSelectDiscountMode(id: string) {
    let modeElement = document.getElementById(id) as HTMLElement;
    modeElement.classList.remove('d-none');
  }

  hideSelectDiscountMode(id: string) {
    if (id) {
      let modeElement = document.getElementById(id) as HTMLElement;

      modeElement.classList.add('d-none');
    }
  }

  delayedHideSelectDiscountMode(id: string) {
    setTimeout(() => {
      let modeElement = document.getElementById(id) as HTMLElement;
      modeElement.classList.add('d-none');
    }, 200);
  }

  onSelectedItem(rowID, itemID) {
    let description = document.getElementById(
      'productdescription' + rowID
    ) as HTMLFormElement;
    let price = document.getElementById(
      'tbproductprice' + rowID
    ) as HTMLFormElement;

    let item = this.itemList.find((item) => item.id === itemID);
    let itemPrice;

    if (this.isPurchaseRequest || this.isCreateGR) {
      itemPrice = item.purchasePrice.toString();
    } else {
      itemPrice = item.sellingPrice.toString();
    }

    itemPrice = this.currencyPipe.transform(itemPrice, '');
    price.value = itemPrice.replace('$', '');

    this.rows[rowID - 1].itemId = item.id;
    this.rows[rowID - 1].itemName = item.name;
    this.rows[rowID - 1].itemCode = item.itemCode;
    this.rows[rowID - 1].unitPrice = item.sellingPrice;
    this.rows[rowID - 1].inStockFGQuantity = item.fgAvailableQuantity;
    this.rows[rowID - 1].inStockFOCQuantity = item.focAvailableQuantity;

    this.rows[rowID - 1].stockType == this.stockType.FG;
    // if (item.isItemSet) {
    //   let tempDescription = item.name + '\n';
    //   item.itemSet.forEach((element,index) => {
    //     tempDescription = tempDescription + element
    //   })
    // } else {
    //   description.value = item.name;
    // }
    description.value = item.name;
    this.rows[rowID - 1].description = item.name;
    this.isSubmit = false;
    this.clearFormValidate(rowID);
    this.CalculateProductRow(rowID);
  }

  onSelectedStockType(rowID, typeID: number) {
    let price = document.getElementById(
      'tbproductprice' + rowID
    ) as HTMLFormElement;

    let discount = document.getElementById(
      'tbproductdiscount' + rowID
    ) as HTMLFormElement;

    this.rows[rowID - 1].stockType = typeID;

    if (typeID == StockType.FOC) {
      this.rows[rowID - 1].unitPrice = 0;
      this.rows[rowID - 1].discount = 0;

      price.value = '0.00';
      discount.value = '0.00';
    } else {
      if (this.rows[rowID - 1].itemId) {
        this.onSelectedItem(rowID, this.rows[rowID - 1].itemId);
      }
    }

    this.CalculateProductRow(rowID);
  }

  onSelectedTax(event) {
    if (event.target.value == -1) {
      this.isVatExempted = true;
      this.taxRate = 0.0;
    } else {
      let tempVal = event.target.value ? parseFloat(event.target.value) : 0.0;
      this.taxRate = tempVal;
      this.isVatExempted = false;
    }

    this.CalculateSummary();
    this.rows.forEach((element, index) => {
      this.formatMoneyAllColumn((index + 1).toString());
      this.CalculateProductRow(index + 1);
    });
  }

  onSelectedWHT(whtObj, rowID) {
    this.CalculateWhtByRow(whtObj, rowID);

    let sumWhtPrice = document.getElementById(
      'summaryWhtPrice'
    ) as HTMLFormElement;

    let tempSumWhtPrice = this.currencyPipe.transform(this.CalculateWht(), '');

    sumWhtPrice.value = tempSumWhtPrice
      ? tempSumWhtPrice.replace('$', '')
      : '0.00';

    this.CalculateSummary();
  }

  SetDescription(event, rowID) {
    this.clearFormValidate(rowID);
    this.rows[rowID - 1].description = event.target.value;
  }

  isWithinRange(value: number, min: number, max: number): boolean {
    return value >= 0 && value <= max;
  }

  CalculateProductRow(rowID) {
    let price = document.getElementById(
      'tbproductprice' + rowID
    ) as HTMLFormElement;
    let discount = document.getElementById(
      'tbproductdiscount' + rowID
    ) as HTMLFormElement;
    let quantity = document.getElementById(
      'tbproductamount' + rowID
    ) as HTMLFormElement;
    let priceNet = document.getElementById(
      'tbproductnetamount' + rowID
    ) as HTMLElement;
    let wht = document.getElementById('tbproductwht' + rowID) as HTMLElement;

    let colQuantity = document.getElementById(
      'tdquantity' + rowID
    ) as HTMLElement;
    let colPrice = document.getElementById(
      'tdproductprice' + rowID
    ) as HTMLElement;
    let colDiscount = document.getElementById(
      'tbproductdiscountborder' + rowID
    ) as HTMLElement;

    colQuantity.classList.remove('table-field-invalid');
    colPrice.classList.remove('table-field-invalid');
    colDiscount.classList.remove('table-field-invalid');

    let numPrice = price.value
      ? parseFloat(price.value.replace(/,/g, ''))
      : 0.0;
    let numDiscount = discount.value
      ? parseFloat(discount.value.replace(/,/g, ''))
      : 0.0;
    let numQuantity = quantity.value
      ? parseFloat(quantity.value.replace(/,/g, ''))
      : 1;

    if (!this.isWithinRange(numPrice, 0, this.maxLengthOfValue)) {
      colPrice.classList.add('table-field-invalid');
    }

    if (!this.isWithinRange(numDiscount, 0, this.maxLengthOfValue)) {
      colDiscount.classList.add('table-field-invalid');
    }

    if (!this.isWithinRange(numQuantity, 0, this.maxLengthOfValue)) {
      colQuantity.classList.add('table-field-invalid');
    }

    if (
      this.isWithinRange(numPrice, 0, this.maxLengthOfValue) &&
      this.isWithinRange(numDiscount, 0, this.maxLengthOfValue) &&
      this.isWithinRange(numQuantity, 0, this.maxLengthOfValue)
    ) {
      this.rows[rowID - 1].unitPrice = numPrice;
      this.rows[rowID - 1].discount = numDiscount;
      this.rows[rowID - 1].quantity = this.isQuantityDecimal
        ? numQuantity
        : Math.floor(numQuantity);

      let total = this._calculateService.calculateBeforeTax(
        this.rows[rowID - 1],
        this.isExcludeTax,
        this.taxRate
      );

      let formatTotal = this.currencyPipe.transform(total, '');
      let totalStr = formatTotal.replace('$', '');

      let numWht = this._calculateService.calculateWHT(
        total,
        this.rows[rowID - 1].whtRate
      );
      let strWht = this.convertNumbertoMoneyFormat(numWht);
      priceNet.innerText = totalStr;

      wht.innerText = strWht;
      this.CalculateSummary();
    }
  }

  validateProductRow(rowID?: number) {
    if (!this.isCreditDebitMode && !this.isCreateInvoice && !this.isCreateGR) {
      const priceInput = document.getElementById(
        'tbproductprice' + rowID
      ) as HTMLInputElement;

      const discountInput = document.getElementById(
        'tbproductdiscount' + rowID
      ) as HTMLInputElement;

      if (this.quantityItemDefault[rowID - 1] === undefined) {
        this.quantityItemDefault[rowID - 1] = 0;
      }

      const numPrice = parseFloat(priceInput.value.replace(/,/g, ''));
      const numDiscount = parseFloat(discountInput.value.replace(/,/g, ''));

      let netAmount = document.getElementById(
        'tbproductnetamount' + rowID
      ) as HTMLElement;
      let tempNetAmount = netAmount.innerText
        ? parseFloat(netAmount.innerText.replace(/,/g, ''))
        : 0;

      this.numUnitPriceArray[rowID - 1] = numPrice;
      this.numDiscountArray[rowID - 1] = numDiscount;
      this.tempNetAmountArray[rowID - 1] = tempNetAmount;

      this.unitPriceInvalid[rowID - 1] = this.numUnitPriceArray[rowID - 1] < 0;

      if (this.rows[rowID - 1].isDiscountByPercent) {
        this.itemInvalid[rowID - 1] =
          this.numDiscountArray[rowID - 1] > 100 ||
          this.numDiscountArray[rowID - 1] < 0 ||
          this.numUnitPriceArray[rowID - 1] < 0 ||
          this.tempNetAmountArray[rowID - 1] < 0;

        this.discountInvalid[rowID - 1] =
          this.numDiscountArray[rowID - 1] > 100 ||
          this.numDiscountArray[rowID - 1] < 0;
      } else {
        this.itemInvalid[rowID - 1] =
          this.numDiscountArray[rowID - 1] >
            this.numUnitPriceArray[rowID - 1] ||
          this.numDiscountArray[rowID - 1] < 0 ||
          this.numUnitPriceArray[rowID - 1] < 0 ||
          this.tempNetAmountArray[rowID - 1] < 0;

        this.discountInvalid[rowID - 1] =
          this.numDiscountArray[rowID - 1] >
            this.numUnitPriceArray[rowID - 1] ||
          this.numDiscountArray[rowID - 1] < 0;
      }

      this.validateSumGrandTotal();
      this.CalculateProductRow(rowID);
      this.itemInvalidChange.emit(this.itemInvalid);
    }

    if (this.isCreditNote || this.isCreateInvoice) {
      const quantityInput = document.getElementById(
        'tbproductamount' + rowID
      ) as HTMLInputElement;

      const priceInput = document.getElementById(
        'tbproductprice' + rowID
      ) as HTMLInputElement;

      const numQuantity = quantityInput.value
        ? this.isQuantityDecimal
          ? parseFloat(quantityInput.value.replace(/,/g, ''))
          : Math.floor(parseFloat(quantityInput.value.replace(/,/g, '')))
        : 0;

      const numPrice = priceInput.value
        ? parseFloat(priceInput.value.replace(/,/g, ''))
        : 0;

      this.numQuantityArray[rowID - 1] = this.isQuantityDecimal
        ? numQuantity
        : Math.floor(numQuantity);
      this.numUnitPriceArray[rowID - 1] = numPrice;

      let isQuantityValid = true;
      let isPriceValid = true;

      if (
        this.numQuantityArray[rowID - 1] < 0 ||
        this.numQuantityArray[rowID - 1] > this.quantityItemDefault[rowID - 1]
      ) {
        quantityInput.classList.add('text-danger');
        isQuantityValid = false;
        this.quantityInvalid[rowID - 1] = true;
      } else {
        quantityInput.classList.remove('text-danger');
        this.quantityInvalid[rowID - 1] = false;
      }

      if (
        this.numUnitPriceArray[rowID - 1] < 0 ||
        this.numUnitPriceArray[rowID - 1] > this.unitPriceItemDefault[rowID - 1]
      ) {
        priceInput.classList.add('text-danger');
        isPriceValid = false;
        this.unitPriceInvalid[rowID - 1] = true;
      } else {
        priceInput.classList.remove('text-danger');
        this.unitPriceInvalid[rowID - 1] = false;
      }

      this.itemInvalid[rowID - 1] = !(isQuantityValid && isPriceValid);

      this.CalculateProductRow(rowID);
      this.itemInvalidChange.emit(this.itemInvalid);
    }

    if (this.isDebitNote || this.isCreateGR) {
      const quantityInput = document.getElementById(
        'tbproductamount' + rowID
      ) as HTMLInputElement;

      const priceInput = document.getElementById(
        'tbproductprice' + rowID
      ) as HTMLInputElement;

      const discountInput = document.getElementById(
        'tbproductdiscount' + rowID
      ) as HTMLInputElement;

      if (this.quantityItemDefault[rowID - 1] === undefined) {
        this.quantityItemDefault[rowID - 1] = 0;
      }

      const numQuantity = quantityInput.value
        ? this.isQuantityDecimal
          ? parseFloat(quantityInput.value.replace(/,/g, ''))
          : Math.floor(parseFloat(quantityInput.value.replace(/,/g, '')))
        : 0;
      const numPrice = parseFloat(priceInput.value.replace(/,/g, ''));
      const numDiscount = parseFloat(discountInput.value.replace(/,/g, ''));

      let netAmount = document.getElementById(
        'tbproductnetamount' + rowID
      ) as HTMLElement;
      let tempNetAmount = netAmount.innerText
        ? parseFloat(netAmount.innerText.replace(/,/g, ''))
        : 0;

      this.numQuantityArray[rowID - 1] = this.isQuantityDecimal
        ? numQuantity
        : Math.floor(numQuantity);
      this.numUnitPriceArray[rowID - 1] = numPrice;
      this.numDiscountArray[rowID - 1] = numDiscount;
      this.tempNetAmountArray[rowID - 1] = tempNetAmount;

      this.quantityInvalid[rowID - 1] = this.numQuantityArray[rowID - 1] < 0;
      this.quantityWarning[rowID - 1] =
        this.numQuantityArray[rowID - 1] > this.quantityItemDefault[rowID - 1];

      this.unitPriceInvalid[rowID - 1] = this.numUnitPriceArray[rowID - 1] < 0;

      if (this.rows[rowID - 1].isDiscountByPercent) {
        this.itemInvalid[rowID - 1] =
          this.numQuantityArray[rowID - 1] < 0 ||
          this.numDiscountArray[rowID - 1] > 100 ||
          this.numDiscountArray[rowID - 1] < 0 ||
          this.numUnitPriceArray[rowID - 1] < 0 ||
          this.tempNetAmountArray[rowID - 1] < 0;

        this.discountInvalid[rowID - 1] =
          this.numDiscountArray[rowID - 1] > 100 ||
          this.numDiscountArray[rowID - 1] < 0;
      } else {
        this.itemInvalid[rowID - 1] =
          this.numQuantityArray[rowID - 1] < 0 ||
          this.numDiscountArray[rowID - 1] >
            this.numUnitPriceArray[rowID - 1] ||
          this.numDiscountArray[rowID - 1] < 0 ||
          this.numUnitPriceArray[rowID - 1] < 0 ||
          this.tempNetAmountArray[rowID - 1] < 0;

        this.discountInvalid[rowID - 1] =
          this.numDiscountArray[rowID - 1] >
            this.numUnitPriceArray[rowID - 1] ||
          this.numDiscountArray[rowID - 1] < 0;
      }

      this.validateSumGrandTotal();
      this.CalculateProductRow(rowID);
      this.itemInvalidChange.emit(this.itemInvalid);
    }
  }

  validateSumGrandTotal() {
    let sumGrandTotal = document.getElementById(
      'grandTotalPrice'
    ) as HTMLElement;
    let tempSumGrandTotal = sumGrandTotal.innerText
      ? parseFloat(sumGrandTotal.innerText.replace(/,/g, ''))
      : 0;

    const totalDiscountInput = document.getElementById(
      'discountAllBill'
    ) as HTMLInputElement;
    let numTotalDiscountInput = totalDiscountInput.value
      ? parseFloat(totalDiscountInput.value.replace(/,/g, ''))
      : 0.0;

    this.sumTotalInvalid = tempSumGrandTotal < 0;
    this.sumTotalInvalidChange.emit(this.sumTotalInvalid);

    if (!this.isDebitNote && !this.isCreateInvoice && !this.isCreateGR) {
      this.totalDiscountInvalid = numTotalDiscountInput < 0;
      this.totalDiscountInvalidChange.emit(this.totalDiscountInvalid);
    }
    if (this.isCreateInvoice || this.isCreateGR) {
      this.totalDiscountInvalid =
        numTotalDiscountInput > this.totalDiscountDefault ||
        numTotalDiscountInput < 0;
      this.totalDiscountInvalidChange.emit(this.totalDiscountInvalid);
    }
    if (this.isDebitNote) {
      this.totalDiscountInvalid = numTotalDiscountInput < 0;
      this.totalDiscountInvalidChange.emit(this.totalDiscountInvalid);
    }
  }

  isAnyItemInvalid(): boolean {
    return this.itemInvalid.some((invalid) => invalid);
  }

  isInvalidSumTotal(): boolean {
    return this.sumTotalInvalid;
  }

  CalculateWhtByRow(whtObj, rowID) {
    let wht = document.getElementById('tbproductwht' + rowID) as HTMLElement;
    let showWhtRate = document.getElementById(
      'tbproductwhtRate' + rowID
    ) as HTMLElement;
    let tempVal = whtObj ? parseFloat(whtObj.value) : 0.0;
    this.rows[rowID - 1].whtRate = tempVal;
    let netPrice = this._calculateService.calculateBeforeTax(
      this.rows[rowID - 1],
      this.isExcludeTax,
      this.taxRate
    );
    wht.innerText = this.currencyPipe
      .transform(this._calculateService.calculateWHT(netPrice, tempVal), '')
      .toString()
      .replace('$', '');
    showWhtRate.innerText = whtObj.label;
  }

  CalculateAllVatExemptedRow(): number {
    let sum = 0;
    for (const row of this.rows) {
      if (row.rowType === 'item') {
        sum += (row.unitPrice - row.discount) * row.quantity;
      }
    }
    return sum;
  }

  CalculateAllNet(): number {
    let sum = this.CalculateAllVatExemptedRow();

    let discountAllBill = document.getElementById(
      'discountAllBill'
    ) as HTMLFormElement;

    let numdiscountAllBill = discountAllBill.value
      ? parseFloat(discountAllBill.value.replace(/,/g, ''))
      : 0.0;

    return sum - numdiscountAllBill;
  }

  CalculateTax(): number {
    let sumPrice = this._calculateService.calculateNet(
      this.rows,
      this.isExcludeTax,
      this.taxRate
    );
    let sumTax = 0;

    if (this.taxRate != 0.0) {
      sumTax = sumPrice * (this.taxRate / 100);
    } else {
      sumTax = 0;
    }

    return sumTax;
  }

  CalculateWht(): number {
    let sum = 0;
    let sumPrice = this.CalculateAllNet();
    return sum;
  }

  EditWhtPrice(value): void {
    let sumWhtPrice = document.getElementById(
      'summaryWhtPrice'
    ) as HTMLFormElement;

    if (value) {
      sumWhtPrice.value = value;
      this.quotationForm.patchValue({
        salesWHT: value,
      });
    } else {
      sumWhtPrice.value = '0.00';
      this.quotationForm.patchValue({
        salesWHT: 0.0,
      });
    }

    this.CalculateSummary(true);
  }

  validateLineLimit(event: KeyboardEvent) {
    const textarea = event.target as HTMLTextAreaElement;
    const lines = textarea.value.split('\n');
    const lineLimit = 10;

    if (event.key === 'Enter') {
      if (lines.length >= lineLimit) {
        event.preventDefault();
        return;
      }
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      const value = textarea.value;
      textarea.value =
        value.substring(0, selectionStart) + value.substring(selectionEnd);
      textarea.selectionStart = textarea.selectionEnd = selectionStart + 1;
    } else if (lines.length > lineLimit && event.key !== 'Backspace') {
      event.preventDefault();
    }
    // Calculate the length of the current line
    const currentLine = lines[lines.length - 1];
    const currentLineLength = currentLine.length;

    // Adjust the character limit for the last line as per your requirement
    const characterLimit = 100;

    if (
      lines.length === lineLimit &&
      currentLineLength >= characterLimit &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    }
  }

  limitLines(event: InputEvent, maxLines: number) {
    let text = (event.target as HTMLTextAreaElement).value;
    if (text.length > 0) {
      const lineCount = 1 + text.replace(/[^\n]/g, '').length;
      if (lineCount > maxLines) {
        const textArray = text.split('\n');
        const newText = textArray.reduce((result, line, lineNum, array) => {
          if (lineNum < maxLines) {
            return result.concat('\n').concat(line);
          }
          return result.concat(line);
        });
        (event.target as HTMLTextAreaElement).value = newText;
      }
    }
  }

  CalculateSummary(editWht?: boolean): void {
    var self = this;
    let sumNetPrice = document.getElementById(
      'summaryTaxExclusive'
    ) as HTMLElement;
    let sumTaxPrice = document.getElementById(
      'summaryTaxPrice'
    ) as HTMLFormElement;
    let sumIncludeTaxPrice = document.getElementById(
      'summaryPrice'
    ) as HTMLElement;
    let sumWhtPrice = document.getElementById(
      'summaryWhtPrice'
    ) as HTMLFormElement;
    let discountAllBillPrice = document.getElementById(
      'discountAllBill'
    ) as HTMLFormElement;

    discountAllBillPrice.classList.remove('table-field-invalid');
    sumWhtPrice.classList.remove('table-field-invalid');

    let discount = discountAllBillPrice.value
      ? parseFloat(discountAllBillPrice.value.replace(/,/g, ''))
      : 0.0;

    let sumNet = this._calculateService.calculateNet(
      this.rows,
      this.isExcludeTax,
      this.taxRate,
      discount,
      this.isDiscountByPercent
    );

    let sumVat = this._calculateService.calculateVat(sumNet, this.taxRate);
    let sumTotal = this._calculateService.getTotal(sumNet, sumVat);

    this.amount = sumNet;
    let tempSumNetPrice = this.convertNumbertoMoneyFormat(sumNet);

    let sumWhtValue = parseFloat(sumWhtPrice.value.replace(/,/g, ''));

    if (!editWht) {
      let tempSumWhtPrice = this.convertNumbertoMoneyFormat(
        this._calculateService.getSumWht(
          this.rows,
          this.taxRate,
          discount,
          this.isExcludeTax,
          this.isDiscountByPercent
        )
      );
      sumWhtPrice.value = tempSumWhtPrice ? tempSumWhtPrice : '0.00';
    }

    let tempSumTaxPrice = this.convertNumbertoMoneyFormat(sumVat);
    let tempsumIncludeTaxPrice = this.convertNumbertoMoneyFormat(sumTotal);

    sumNetPrice.innerText = tempSumNetPrice ? tempSumNetPrice : '0.00';
    sumTaxPrice.innerText = tempSumTaxPrice ? tempSumTaxPrice : '0.00';

    sumIncludeTaxPrice.innerText = tempsumIncludeTaxPrice
      ? tempsumIncludeTaxPrice.replace('$', '')
      : '0.00';

    this.CalculateGrandTotal();
  }

  CalculateGrandTotal() {
    let sumWhtPrice = document.getElementById(
      'summaryWhtPrice'
    ) as HTMLFormElement;

    let grandTotalPrice = document.getElementById(
      'grandTotalPrice'
    ) as HTMLElement;

    let discountAllBillPrice = document.getElementById(
      'discountAllBill'
    ) as HTMLFormElement;

    let shippingFeePrice = document.getElementById(
      'shippingFee'
    ) as HTMLFormElement;

    let discount = discountAllBillPrice.value
      ? parseFloat(discountAllBillPrice.value.replace(/,/g, ''))
      : 0.0;

    let shippingFee = shippingFeePrice.value
      ? parseFloat(shippingFeePrice.value.replace(/,/g, ''))
      : 0.0;

    let sumNet = this._calculateService.calculateNet(
      this.rows,
      this.isExcludeTax,
      this.taxRate,
      discount,
      this.isDiscountByPercent
    );
    let sumVat = this._calculateService.calculateVat(sumNet, this.taxRate);
    let sumTotal = this._calculateService.getTotal(sumNet, sumVat);

    let sumTotalDefault = this.oldGrandTotal;

    let sumWhtValue = isNaN(parseFloat(sumWhtPrice.value.replace(/,/g, '')))
      ? 0.0
      : parseFloat(sumWhtPrice.value.replace(/,/g, ''));
    let sumGrandTotal =
      this._calculateService.getGrandTotal(sumTotal, sumWhtValue) + shippingFee;

    if (this.isCreditDebitMode) {
      let refNewPrice = document.getElementById('refNewPrice') as HTMLElement;
      let diffPrice = this.isCreditNote
        ? Math.abs(this.refOldPrice - sumTotal)
        : this.refOldPrice + sumTotal;
      refNewPrice.innerText = this.convertNumbertoMoneyFormat(diffPrice);
    }

    if (this.isDebitNote) {
      let sumGrandTotal = sumTotal + shippingFee;

      let tempGrandTotal = this.currencyPipe.transform(sumGrandTotal, '');
      grandTotalPrice.innerText = tempGrandTotal
        ? tempGrandTotal.replace('$', '')
        : '0.00';

      // if (sumGrandTotal < 0) {
      //   this.itemInvalid = true;
      // }
    }

    if (!this.isDebitNote) {
      let tempGrandTotal = this.currencyPipe.transform(sumGrandTotal, '');
      grandTotalPrice.innerText = tempGrandTotal
        ? tempGrandTotal.replace('$', '')
        : '0.00';
    }
  }

  async openConfirmModal(content: any, isDraft: boolean): Promise<void> {
    let isConfirm = true;
    let isDescriptionNotFill = false;
    let isItemNotSelected = false;
    let isDiscountInvalid = false;
    let isSumDiscountInvalid = false;
    let isWhtInvalid = false;
    let isDateDiffInvalid = false;

    let contentNameText = this.isEditing ? content.name : this.componentName;
    let title;
    let detail;
    if (!isDraft) {
      if (
        !this.isPurchaseRequest &&
        !this.isCreateInvoice &&
        !this.isCreateGR
      ) {
        title = this._translateService.instant('Quotation.CreateTitle');
        detail = this._translateService.instant('Quotation.Create');
      }
      if (this.isPurchaseRequest) {
        title = this._translateService.instant('Modal.CreatePR');
        detail = this._translateService.instant('Modal.AreYouSureToCreatePR');
      }
    } else {
      if (this.isEditing) {
        if (
          !this.isPurchaseRequest &&
          !this.isCreateInvoice &&
          !this.isCreateGR
        ) {
          title = this._translateService.instant('Quotation.SaveDraftTitle');
          detail = this._translateService.instant('Quotation.SaveDraft');
        }
        if (this.isPurchaseRequest) {
          title = this._translateService.instant('Modal.SaveDraftPR');
          detail = this._translateService.instant(
            'Modal.AreYouSureToSaveDraftPR'
          );
        }
      } else {
        if (
          !this.isPurchaseRequest &&
          !this.isCreateInvoice &&
          !this.isCreateGR
        ) {
          title = this._translateService.instant('Quotation.EditTitle');
          detail = this._translateService.instant('Quotation.Edit');
        }
        if (this.isPurchaseRequest) {
          title = this._translateService.instant('Modal.SaveDraftPR');
          detail = this._translateService.instant(
            'Modal.AreYouSureToSaveDraftPR'
          );
        }
        if (this.isCreateInvoice) {
          title = this._translateService.instant('Modal.CreateSeparateIV');
          detail = this._translateService.instant(
            'Modal.AreYouSureToCreateSeparateIV'
          );
        }
        if (this.isCreateGR) {
          title = this._translateService.instant('Modal.CreateSeparateGR');
          detail = this._translateService.instant(
            'Modal.AreYouSureToCreateSeparateGR'
          );
        }
      }
    }

    this.isSubmit = true;

    this.rows.forEach((element, index) => {
      if (!element.description) {
        let descForm = document.getElementById(
          'tbproductdescription' + (index + 1)
        ) as HTMLElement;
        descForm.classList.add('table-field-invalid');
        isDescriptionNotFill = true;
      }
      if (element.rowType === 'item' && !element.itemId) {
        let itemForm = document.getElementById(
          'tbproductcode' + (index + 1)
        ) as HTMLElement;
        itemForm.classList.add('table-field-invalid');
        isItemNotSelected = true;
      }
      if (
        element.rowType === 'item' &&
        element.isDiscountByPercent &&
        (element.discount > 100 || element.discount < 0)
      ) {
        let discountBorderForm = document.getElementById(
          'tbproductdiscountborder' + (index + 1)
        ) as HTMLElement;
        discountBorderForm.classList.add('table-field-invalid');
        isDiscountInvalid = true;
      }
      if (
        element.rowType === 'item' &&
        !element.isDiscountByPercent &&
        element.unitPrice * element.quantity - element.discount < 0
      ) {
        let discountBorderForm = document.getElementById(
          'tbproductdiscountborder' + (index + 1)
        ) as HTMLElement;
        discountBorderForm.classList.add('table-field-invalid');
        isDiscountInvalid = true;
      }
    });

    let sumDiscount = this.quotationForm.controls.discount.value;
    let sumNet = this._calculateService.calculateNet(
      this.rows,
      this.isExcludeTax,
      this.taxRate,
      sumDiscount,
      this.isDiscountByPercent
    );

    if (sumNet < 0) {
      let discountBorderForm = document.getElementById(
        'discountAllBill'
      ) as HTMLElement;
      discountBorderForm.classList.add('table-field-invalid');
      isSumDiscountInvalid = true;
    }

    let dateFormValue = this.quotationForm.controls.documentCreateDate.value;
    let dateToValue = this.quotationForm.controls.documentExpireDate.value;

    let tempDateForm = new Date(dateFormValue);
    let tempDateTo = new Date(dateToValue);

    if (tempDateForm > tempDateTo) {
      isDateDiffInvalid = true;
      this.isDateError = true;
    } else {
      this.isDateError = false;
    }

    let sumGrandTotal =
      this._calculateService.getTotal(sumNet, this.taxRate) -
      this.quotationForm.controls.salesWHT.value;

    if (sumGrandTotal < 0) {
      let WhtForm = document.getElementById('summaryWhtPrice') as HTMLElement;
      WhtForm.classList.add('table-field-invalid');
      isWhtInvalid = true;
    }

    if (
      !this.quotationForm.invalid &&
      !isDescriptionNotFill &&
      !isItemNotSelected &&
      !isDiscountInvalid &&
      !isSumDiscountInvalid &&
      !isWhtInvalid &&
      !isDateDiffInvalid &&
      this.checkUplineCode
      // this.itemInvalid == false
    ) {
      this.openModal(title, detail, isConfirm, isDraft);
    } else {
      this._globalFuncService.scrollToFirstInvalidControl('add-bill-form');
    }
  }

  openCancelModal(content: any): void {
    let isConfirm = false;
    let contentNameText = this.isEditing
      ? this.quotationObj.customerId
      : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.CancelEdit');
    } else {
      var stateForm = this._translateService.instant('General.CancelCreate');
    }

    let title;
    let detail;
    if (this.isEditing) {
      if (
        !this.isPurchaseRequest &&
        !this.isCreateInvoice &&
        !this.isCreateGR
      ) {
        title = this._translateService.instant('Quotation.CancelEditTitle');
        detail = this._translateService.instant('Quotation.CancelEdit');
      }
      if (this.isPurchaseRequest) {
        title = this._translateService.instant('Modal.CancelEditPR');
        detail = this._translateService.instant(
          'Modal.AreYouSureToCancelEditPR'
        );
      }
    } else {
      if (
        !this.isPurchaseRequest &&
        !this.isCreateInvoice &&
        !this.isCreateGR
      ) {
        title = this._translateService.instant('Quotation.CancelCreateTitle');
        detail = this._translateService.instant('Quotation.CancelCreate');
      }
      if (this.isPurchaseRequest) {
        title = this._translateService.instant('Modal.CancelCreatePR');
        detail = this._translateService.instant(
          'Modal.AreYouSureToCancelCreatePR'
        );
      }
      if (this.isCreateInvoice) {
        title = this._translateService.instant('Modal.CancelCreateSeparateIV');
        detail = this._translateService.instant(
          'Modal.AreYouSureToCancelCreateSeparateIV'
        );
      }
      if (this.isCreateGR) {
        title = this._translateService.instant('Modal.CancelCreateSeparateGR');
        detail = this._translateService.instant(
          'Modal.AreYouSureToCancelCreateSeparateGR'
        );
      }
    }

    this.openModal(title, detail, isConfirm);
  }

  openModal(
    title: string,
    detail: string,
    IsConfirm: boolean,
    isDraft?: boolean
  ): void {
    if (!this.quotationForm.invalid) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        if (IsConfirm) {
          this.Submit(isDraft);
        } else {
          if (
            !this.isPurchaseRequest &&
            !this.isCreateInvoice &&
            !this.isCreateGR
          ) {
            this._router.navigate([
              this._documentService.quotation.PathURL + '/view',
            ]);
          }
          if (this.isCreateInvoice) {
            this._router.navigate([
              this._documentService.saleOrder.DetailPathURL +
                '/' +
                this.idDocument,
            ]);
          }
          if (this.isPurchaseRequest) {
            this._router.navigate([
              this._documentService.purchaseRequest.PathURL + '/view',
            ]);
          }
          if (this.isCreateGR) {
            this._router.navigate([
              this._documentService.purchaseOrder.DetailPathURL +
                '/' +
                this.idDocument,
            ]);
          }
        }
      });
    } else {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        if (
          !this.isPurchaseRequest &&
          !this.isCreateInvoice &&
          !this.isCreateGR
        ) {
          this._router.navigate([
            this._documentService.quotation.PathURL + '/view',
          ]);
        }
        if (this.isCreateInvoice) {
          this._router.navigate([
            this._documentService.saleOrder.DetailPathURL +
              '/' +
              this.idDocument,
          ]);
        }
        if (this.isPurchaseRequest) {
          this._router.navigate([
            this._documentService.purchaseRequest.PathURL + '/view',
          ]);
        }
        if (this.isCreateGR) {
          this._router.navigate([
            this._documentService.purchaseOrder.DetailPathURL +
              '/' +
              this.idDocument,
          ]);
        }
      });
    }
  }

  Submit(isDraft?: boolean) {
    this.blockUI.start();
    let formData = this.prepareFinalData(isDraft);
    console.log(formData);
    this.submitData.emit(formData);
  }

  getFormValue(isDraft?: boolean): any {
    let formData = this.prepareFinalData(isDraft);

    return formData.quotationForm;
  }

  prepareFinalData(isDraft?: boolean): any {
    var self = this;
    let discountAllBillPrice = document.getElementById(
      'discountAllBill'
    ) as HTMLFormElement;

    let shippingFeePrice = document.getElementById(
      'shippingFee'
    ) as HTMLFormElement;

    let discount = discountAllBillPrice.value
      ? parseFloat(discountAllBillPrice.value.replace(/,/g, ''))
      : 0.0;

    let shippingFee = shippingFeePrice.value
      ? parseFloat(shippingFeePrice.value.replace(/,/g, ''))
      : 0.0;

    this.quotationForm.controls.issuerId.patchValue(this.currentUser.id);
    let dateFormValue = this.quotationForm.controls.documentCreateDate.value;
    let dateToValue = this.quotationForm.controls.documentExpireDate.value;
    let tempDateForm = new Date(dateFormValue);
    let tempDateTo = this.isUseValidDate
      ? new Date(dateToValue)
      : new Date(dateFormValue);

    let startDateFrom = moment(tempDateForm).startOf('day').format();
    let endDateTo = moment(tempDateTo).endOf('day').format();

    let sumWht = document.getElementById('summaryWhtPrice') as HTMLFormElement;
    this.quotationForm.patchValue({
      issuerId: this.currentUser.id,
      isExcludeTax: this.isExcludeTax,
      shippingAddressId: this.shippingAddressId,
      shippingAddressDetail: this.shippingAddressDetail,
      shippingPhone: this.shippingPhone,
      shippingCustomerName: this.shippingCustomerName,
      shippingCustomerTaxId: this.shippingCustomerTaxId,
      taxInvoiceAddressId: this.taxInvoiceAddressId,
      taxInvoiceAddressDetail: this.taxInvoiceAddressDetail,
      taxInvoicePhone: this.taxInvoicePhone,
      taxInvoiceCustomerName: this.taxInvoiceCustomerName,
      taxInvoiceCustomerTaxId: this.taxInvoiceCustomerTaxId,
      taxRate: this.taxRate,
      isProductReceived: this.isProductReceive,
      isProductSent: this.isProductSent,
      isVatExempt: this.isVatExempted,
      discount: discount,
      shippingFee: shippingFee,
      salesWHT: parseFloat(sumWht.value.replace(/,/g, '')),
      isDiscountByPercent: this.isDiscountByPercent,
      documentCreateDate: startDateFrom,
      documentExpireDate: endDateTo,
    });

    let tempRowsData: QuotationItem[] = [];
    let itemCount = 0;
    let ordinalDesCount = 0;
    self.rows.forEach((element: TempRowQuotation, index) => {
      if (element.rowType == 'item') {
        let item: QuotationItem = {
          ordinal: itemCount,
          itemId: element.itemId,
          quantity: element.quantity,
          unit: element.unit,
          stockType: element.stockType,
          salesWHT: element.salesWHT,
          whtRate: element.whtRate,
          unitPrice: element.unitPrice,
          remarks: element.remarks,
          uniqueId: element.uniqueId,
          preTaxDiscount: element.preTaxDiscount,
          discount: element.discount,
          description: [{ ordinal: 0, description: element.description }],
          isDiscountByPercent: element.isDiscountByPercent,
        };

        tempRowsData.push(item);
        itemCount++;
        ordinalDesCount = 0;
      } else {
        ordinalDesCount++;
        if (tempRowsData[itemCount - 1]) {
          tempRowsData[itemCount - 1].description.push({
            ordinal: ordinalDesCount,
            description: element.description,
          });
        } else {
        }
      }
    });
    this.quotationForm.controls.item.patchValue(tempRowsData);
    this.quotationForm.controls[this.docCodeColumn].patchValue(this.docCode);
    // this.quotationForm.controls.quotationCode.patchValue(this.docCode);
    const formData = {
      docID: this.docID,
      isDraft: isDraft,
      isEditing: this.isEditing,
      quotationForm: this.quotationForm.value,
    };
    return formData;
  }
  // get itemrow length
  get itemrowLength(): number {
    return this.rows.filter((row) => row.rowType === 'item').length;
  }

  GetAddressTemplate(customerAddressObj): string {
    return this._globalFuncService.GetAddressTemplate(customerAddressObj);
  }
}
