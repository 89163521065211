<div class="modal-header">
  <h5 class="modal-title" id="addressModalTitle">
    {{
      (targetType == addressType.Billing
        ? "Address.registeredAddress"
        : "Address.mailingAddress"
      ) | translate
    }}
  </h5>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">
      <i data-feather="x" size="22"></i>
    </span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <form [formGroup]="formGroup">
    <div class="col-md-12 col-sm-12 ctm-addr">
      <!-- <div class="form-group row">
        <div class="col-md-12 col-form-label">
          <b>{{ "Address.registeredAddress" | translate }}</b>
        </div>
      </div> -->

      <div class="form-group row">
        <div
          class="col-md-3 col-form-label"
          [ngClass]="{
            'pt-1': targetType == addressType.Shipping
          }"
        >
          {{ "Customer.contactType" | translate }}
        </div>
        <div class="col-md-6 d-flex align-items-center">
          <div class="mr-3 custom-control custom-radio">
            <input
              type="radio"
              [value]="true"
              id="addr_businessTRadio1"
              formControlName="isLegalAddr"
              name="isLegalAddr"
              class="custom-control-input"
              (change)="isBusinessType(true)"
              checked
            />
            <label class="custom-control-label" for="addr_businessTRadio1">{{
              "Customer.JuristicPerson" | translate
            }}</label>
          </div>
          <div class="custom-control custom-radio">
            <input
              type="radio"
              [value]="false"
              id="addr_businessTRadio2"
              formControlName="isLegalAddr"
              name="isLegalAddr"
              class="custom-control-input"
              (change)="isBusinessType(false)"
              checked=""
            />
            <label class="custom-control-label" for="addr_businessTRadio2">{{
              "Customer.OrdinaryPerson" | translate
            }}</label>
          </div>
        </div>
        <div class="col-md-3">
          <a
            (click)="copyFromBilling()"
            *ngIf="targetType == addressType.Shipping"
            class="btn btn-primary"
            ><i data-feather="copy" class="mr-1"></i
            >{{ "Address.copy" | translate }}</a
          >
        </div>
      </div>

      <div class="form-group row">
        <div
          class="col-md-6"
          [ngClass]="{
            'col-md-12': f.isLegalAddr.value == true
          }"
        >
          <div class="col-form-label">
            {{
              (f.isLegalAddr.value == true
                ? "Address.contactPerson"
                : "Customer.firstName"
              ) | translate
            }}
            <span class="text-danger">*</span>
          </div>
          <input
            class="form-control"
            formControlName="c_fname"
            #c_nameInput
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_fname?.invalid) ||
                (f.c_fname?.invalid &&
                  (f.c_fname?.dirty || f.c_fname?.touched)) ||
                (f.c_fname?.value == '' &&
                  (f.c_fname?.dirty || f.c_fname?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_fname?.invalid) ||
                (f.c_fname.invalid && (f.c_fname.dirty || f.c_fname.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_fname.errors?.required">
                {{ "Customer.nameRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-none col-md-6"
          [ngClass]="{
            'd-none': f.isLegalAddr.value == true
          }"
          [ngClass]="{
            'd-block': f.isLegalAddr.value == false
          }"
        >
          <div class="col-form-label">
            {{ "Customer.lastName" | translate }}
            <span class="text-danger">*</span>
          </div>
          <input
            class="form-control"
            formControlName="c_lname"
            #c_nameInput
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit &&
                  !f.c_lname?.value &&
                  f.isLegalAddr.value == false) ||
                (f.c_lname?.touched &&
                  f.c_lname?.value == '' &&
                  f.isLegalAddr.value == false)
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit &&
                  !f.c_lname?.value &&
                  f.isLegalAddr.value == false) ||
                (f.c_lname?.touched &&
                  f.c_lname?.value == '' &&
                  f.isLegalAddr.value == false)
            }"
          >
            <div>
              <div
                *ngIf="
                  (isSubmit &&
                    !f.c_lname?.value &&
                    f.isLegalAddr.value == false) ||
                  (f.c_lname?.touched &&
                    f.c_lname?.value == '' &&
                    f.isLegalAddr.value == false)
                "
              >
                {{ "Customer.lastNameRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="form-group row"
        [ngClass]="{
          'd-none': f.isLegalAddr.value == false
        }"
      >
        <div class="col-md-3 col-form-label">
          {{ "Customer.office" | translate }}
        </div>
        <div class="col-md-9 d-flex align-items-center">
          <div class="custom-control custom-radio mr-2">
            <input
              type="radio"
              id="addr_havecompany2"
              value="1"
              name="branchType"
              class="custom-control-input"
              formControlName="branchType"
              checked
            />
            <label class="custom-control-label" for="addr_havecompany2">{{
              "Customer.headOffice" | translate
            }}</label>
          </div>
          <div class="custom-control custom-radio mr-2">
            <input
              type="radio"
              id="addr_havecompany3"
              value="2"
              name="branchType"
              class="custom-control-input"
              formControlName="branchType"
            />
            <label class="custom-control-label" for="addr_havecompany3">{{
              "Customer.branch" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div
        class="form-group row"
        [ngClass]="{
          'd-none': f.isLegalAddr.value == false
        }"
        *ngIf="f.branchType.value === '2'"
      >
        <div class="col-md-12">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="helpInputTop"
                >{{ "Customer.branchCodeLabel" | translate }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="branchNumber"
                id="helpInputTop"
                type="number"
                (blur)="
                  f.branchNumber.setValue(
                    f.branchNumber.value.toString().padStart(5, '0')
                  )
                "
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                [inputMode]="'numeric'"
                placeholder="{{ 'Customer.branchCodePlaceholder' | translate }}"
                pattern="\d*"
                maxlength="10"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit &&
                      f.branchType.value === '2' &&
                      !f.branchNumber?.value) ||
                    f.branchNumber?.value == '00000' ||
                    (!f.branchNumber?.value && f.branchNumber?.touched) ||
                    (f.branchNumber.invalid &&
                      (f.branchNumber.dirty || f.branchNumber.touched))
                }"
              />

              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    f.branchNumber.invalid &&
                    (f.branchNumber.dirty || f.branchNumber.touched)
                }"
              >
                <div>
                  <div *ngIf="f.branchNumber.errors?.message">
                    {{ f.branchNumber.errors.message }}
                  </div>
                  <div *ngIf="f.branchNumber?.value == '00000'">
                    {{ "Customer.invalidAllZeroBranchNumber" | translate }}
                  </div>
                  <div *ngIf="f.branchNumber.errors?.maxlength">
                    {{ "Handle.Exceed" | translate }} 5
                    {{ "Handle.Char" | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label for="helpInputTop"
                >{{ "Customer.branchNameLabel" | translate }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="branchName"
                placeholder="{{ 'Customer.branchNamePlaceholder' | translate }}"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit &&
                      f.branchType.value == '2' &&
                      !f.branchName?.value) ||
                    (f.branchType.value == '2' &&
                      !f.branchName?.value &&
                      f.branchName?.touched)
                }"
              />

              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    (isSubmit &&
                      f.branchType.value == '2' &&
                      !f.branchName?.value) ||
                    (f.branchType.value == '2' &&
                      !f.branchName?.value &&
                      f.branchName?.touched)
                }"
              >
                <div>
                  <div
                    *ngIf="
                      (isSubmit &&
                        f.branchType.value == '2' &&
                        !f.branchName?.value) ||
                      (f.branchType.value == '2' &&
                        !f.branchName?.value &&
                        f.branchName?.touched)
                    "
                  >
                    {{ "Customer.branchNameRequiredError" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row pt-2 pb-2">
        <div class="col-md-3">
          {{ "Customer.idNumber" | translate }}
          <span class="text-danger" *ngIf="targetType == addressType.Billing"
            >*</span
          >
        </div>
        <div class="col-md-9">
          <div class="min-h-screen">
            <div id="ContactPersonalID">
              <input
                *ngFor="let i of otpLengthArray; let index = index"
                [ngClass]="'siam_input_p_id'"
                [id]="'addr-otp-field' + i"
                [style.marginRight.px]="
                  index === 0 || index === 4 || index === 9 ? 10 : 0
                "
                [style.borderColor]="
                  isCheckedTaxId
                    ? (!check_pid_empty && isValidTaxId ? '#28c76f' : '#ea5455' )
                    : '#a1a1a180'
                "
                type="number"
                [inputMode]="'numeric'"
                [pattern]="'[0-9]*'"
                placeholder=""
                maxlength="1"
                min="0"
                (keydown)="onKeyDown($event, i)"
                (input)="onInput($event, i)"
                (paste)="onPaste($event)"
              />
            </div>
            <!-- <p>{{ isCheckedTaxId }}</p> -->
            <div
              class="invalid-feedback"
              [ngClass]="{
                'd-block':
                  check_pid_empty ||
                  (isCheckedTaxId &&
                    !isValidTaxId &&
                    targetType == addressType.Billing)
              }"
            >
              <div *ngIf="check_pid_empty">
                {{ "Customer.fillOutCompletely" | translate }}
              </div>
              <div
                *ngIf="
                  !check_pid_empty &&
                  isCheckedTaxId &&
                  !isValidTaxId &&
                  targetType == addressType.Billing
                "
              >
                {{ "Customer.duplicateTaxID" | translate }}
              </div>
            </div>

            <div
              class="invalid-feedback"
              [ngClass]="{
                'd-block': isSubmit && !isCheckedTaxId && targetType == addressType.Billing
              }"
            >
              <div *ngIf="isSubmit && !isCheckedTaxId && targetType == addressType.Billing">
                กรุณาตรวจสอบรหัส 13 หลักก่อน
              </div>
            </div>

            <div
              class="valid-feedback"
              [ngClass]="{
                'd-block':
                  (!check_pid_empty && isCheckedTaxId) ||
                  (isCheckedTaxId &&
                    !check_pid_empty &&
                    isValidTaxId &&
                    targetType == addressType.Billing)
              }"
            >
              <div *ngIf="check_pid_empty">
                {{ "Customer.fillOutCompletely" | translate }}
              </div>
            </div>
          </div>

          <button
            (click)="checkDuplicateTaxId()"
            class="btn btn-primary mt-1 d-flex"
            [disabled]="isCheckingTaxId || isValidTaxId"
          >
            <span
              *ngIf="isCheckingTaxId"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >{{ "General.Check" | translate }}
          </button>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Address.contactPhone" | translate }}
          <span class="text-danger">*</span>
        </div>
        <div class="col-md-9">
          <input
            class="form-control"
            formControlName="c_phone"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            pattern="[0-9.]+"
            #c_phoneInput
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_phone?.invalid) ||
                (f.c_phone?.invalid &&
                  (f.c_phone?.dirty || f.c_phone?.touched)) ||
                (f.c_phone?.value == '' &&
                  (f.c_phone?.dirty || f.c_phone?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_phone?.invalid) ||
                (f.c_phone.invalid && (f.c_phone.dirty || f.c_phone.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_phone.errors?.required">
                {{ "Address.cPhoneRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Address.address" | translate }} <span class="text-danger">*</span>
        </div>
        <div class="col-md-9">
          <textarea
            formControlName="c_address"
            class="form-control"
            placeholder="{{ 'Address.addressDetails' | translate }}"
            id="label-textarea"
            rows="5"
            maxlength="256"
            #c_addressInput
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_address?.invalid) ||
                (f.c_address?.invalid &&
                  (f.c_address?.dirty || f.c_address?.touched)) ||
                (f.c_address?.value == '' &&
                  (f.c_address?.dirty || f.c_address?.touched))
            }"
          ></textarea>
          <!-- <label for="helpInputTop">{{
                  "Address.addressDetails" | translate
                }}</label>
                <small class="text-muted"
                  ><i> {{ "Address.addressDetailsNote" | translate }}</i></small
                > -->
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_address?.invalid) ||
                (f.c_address.invalid &&
                  (f.c_address.dirty || f.c_address.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_address.errors?.required">
                {{ "Address.cAddressRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.country" | translate }}
            <span class="text-danger">*</span>
          </div>
          <select
            class="custom-select"
            formControlName="c_country"
            #c_countryInput
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_country?.invalid) ||
                (f.c_country?.invalid &&
                  (f.c_country?.dirty || f.c_country?.touched))
            }"
          >
            <option value="">
              -- {{ "Address.selectCountry" | translate }} --
            </option>
            <option value="0" [selected]="f.c_country?.value == '0'">
              {{ "Address.thailand" | translate }}
            </option>

            <!-- <option value="1">{{ "Address.india" | translate }}</option>
                  <option value="2">{{ "Address.thailand2" | translate }}</option> -->
          </select>
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_country?.invalid) ||
                (f.c_country.invalid &&
                  (f.c_country.dirty || f.c_country.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_country.errors?.required">
                {{ "Address.cLocalRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.province" | translate }}
            <span class="text-danger">*</span>
          </div>
          <div class="skeleton-select" *ngIf="isLoadingProvince">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <ng-select
            *ngIf="!isLoadingProvince"
            class="custom-select"
            [clearable]="false"
            placeholder="   -- {{ 'Address.selectProvince' | translate }} --"
            #c_provinceInput
            formControlName="c_province"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_province?.invalid) ||
                (f.c_province?.invalid &&
                  (f.c_province?.dirty || f.c_province?.touched)) ||
                (f.c_province?.value == '' &&
                  (f.c_province?.dirty || f.c_province?.touched))
            }"
            (change)="isSelectProvice($event)"
            [hideSelected]="false"
          >
            <ng-option
              [value]="province.id"
              *ngFor="let province of provinceList"
            >
              <span *ngIf="currentLang != 'en'">
                {{ province.provinceName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ province.provinceNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_province?.invalid) ||
                (f.c_province.invalid &&
                  (f.c_province.dirty || f.c_province.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_province.errors?.required">
                {{ "Address.cCityRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.district" | translate }}
            <span class="text-danger">*</span>
          </div>
          <div class="skeleton-select" *ngIf="isLoadingDistrict">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ng-select
            *ngIf="!isLoadingDistrict"
            class="custom-select"
            #c_districtInput
            [clearable]="false"
            placeholder="-- {{ 'Address.selectDistrict' | translate }} --"
            formControlName="c_district"
            (change)="isSelectDistrict($event)"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_district?.invalid) ||
                (f.c_district?.invalid &&
                  (f.c_district?.dirty || f.c_district?.touched)) ||
                (f.c_district?.value == '' &&
                  (f.c_district?.dirty || f.c_district?.touched))
            }"
            [hideSelected]="false"
          >
            <ng-option
              [value]="district.id"
              *ngFor="let district of districtList"
            >
              <span *ngIf="currentLang != 'en'">
                {{ district.districtName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ district.districtNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_district?.invalid) ||
                (f.c_district.invalid &&
                  (f.c_district.dirty || f.c_district.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_district.errors?.required">
                {{ "Address.cDistrictRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.subdistrict" | translate }}
            <span class="text-danger">*</span>
            <br />
          </div>
          <div class="skeleton-select" *ngIf="isLoadingSubDistrict">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ng-select
            *ngIf="!isLoadingSubDistrict"
            class="custom-select"
            [clearable]="false"
            placeholder="-- {{ 'Address.selectSubDistrict' | translate }} --"
            #c_subDistrictInput
            formControlName="c_subDistrict"
            (change)="getPostCode($event)"
            [hideSelected]="false"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_subDistrict?.invalid) ||
                (f.c_subDistrict?.invalid &&
                  (f.c_subDistrict?.dirty || f.c_subDistrict?.touched)) ||
                (f.c_subDistrict?.value == '' &&
                  (f.c_subDistrict?.dirty || f.c_subDistrict?.touched))
            }"
          >
            <ng-option
              [value]="subDistrict.id"
              *ngFor="let subDistrict of subDistrictList"
            >
              <span *ngIf="currentLang != 'en'">
                {{ subDistrict.subDistrictName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ subDistrict.subDistrictNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_subDistrict?.invalid) ||
                (f.c_subDistrict.invalid &&
                  (f.c_subDistrict.dirty || f.c_subDistrict.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_subDistrict.errors?.required">
                {{ "Address.cSubDistrictRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.postalCode" | translate }}
            <span class="text-danger">*</span>
          </div>
          <input
            #c_postcodeInput
            class="form-control"
            formControlName="c_postcode"
            type="text"
            disabled
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_postcode?.invalid) ||
                (f.c_postcode?.invalid &&
                  (f.c_postcode?.dirty || f.c_postcode?.touched)) ||
                (f.c_postcode?.value == '' &&
                  (f.c_postcode?.dirty || f.c_postcode?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_postcode?.invalid) ||
                (f.c_postcode.invalid &&
                  (f.c_postcode.dirty || f.c_postcode.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_postcode.errors?.required">
                {{ "Address.cPostcodeRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Address.isDefault" | translate }}
        </div>
        <div class="col-md-9 col-form-label">
          <div class="custom-control custom-checkbox">
            <input
              *ngIf="!isEditing"
              type="checkbox"
              class="custom-control-input"
              id="isDefaultCheck"
              formControlName="isDefault"
            />

            <input
              *ngIf="isEditing"
              type="checkbox"
              class="custom-control-input"
              id="isDefaultCheck"
              formControlName="isDefault"
            />

            <input
              *ngIf="isEditing && addrObj.isDefault"
              type="checkbox"
              class="custom-control-input"
              id="isDefaultCheck"
              formControlName="isDefault"
              ngbTooltip="คุณไม่สามารถลบที่อยู่เริ่มต้นได้ แต่สามารถเลือกที่อยู่อื่นเป็นค่าเริ่มต้นแทนได้"
              checked
              disabled
            />

            <label class="custom-control-label" for="isDefaultCheck">{{
              "Address.setAsDefault" | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-between">
  <button
    type="button"
    class="btn btn-secondary m-0"
    (click)="closeModal()"
    rippleEffect
  >
    {{ "Form.Cancel" | translate }}
  </button>
  <a
    type="button"
    class="btn btn-primary m-0"
    (click)="callBack()"
    rippleEffect
  >
    {{ "Form.Submit" | translate }}
  </a>
</div>
